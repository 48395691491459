import { Section } from '../Section/Section';
import { TaskCompleted } from './TaskCompleted';
import { Task } from './Task/Task';
import { StreakAlmostLost } from './StreakAlmostLost/StreakAlmostLost';
import { StreakSaved } from './StreakSaved';
import { useDailyTask } from '../../hooks/useDailyTask';
import { StreaksAnimatePresence } from '../../../../_components/StreaksAnimatePresence';
import { useIsSetOnce } from '../../../../hooks/useIsSetOnce';

export function DailyTask() {
  const data = useDailyTask();
  const vanishedOnce = useIsSetOnce(!data);

  return (
    <StreaksAnimatePresence
      initial={{ marginTop: -12 }}
      animate={{ marginTop: 0 }}
      exit={{ marginTop: -12 }}
    >
      {(data && (data[0] !== 'loading' || !vanishedOnce)) && (
        <Section>
          {data[0] === 'task-completed'
            ? <TaskCompleted/>
            : data[0] === 'streak-almost-lost'
              ? <StreakAlmostLost livesRequired={data[1]} canRestore={data[2]}/>
              : data[0] === 'streak-saved'
                ? <StreakSaved/>
                : <Task data={data[0] === 'loading' ? 'loading' : data[1]}/>}
        </Section>
      )}
    </StreaksAnimatePresence>
  );
}
