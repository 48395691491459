import { createContext, useContext } from 'react';
import type { Wallet } from '@tonconnect/ui-react';
import type { WalletInfoWithOpenMethod, WalletsModal } from '@tonconnect/ui';

interface TonConnectContextType {
  wallet?: {
    data: Wallet | (Wallet & WalletInfoWithOpenMethod);
    address: string;
  };
  isRegisteringWallet: boolean;
  modal: Omit<WalletsModal, 'onStateChange'>;
  disconnectWallet(): void;
}

export const TonConnectContext = createContext<TonConnectContextType | undefined>(undefined);

export function useTonConnect(): TonConnectContextType {
  const value = useContext(TonConnectContext);
  if (!value) {
    throw new Error('Used outside TonConnectProvider');
  }
  return value;
}