import { useMemo } from 'react';

import { findLeagueById, initialLeague } from '../../../leagues/leagues';
import { useStreaksUserState } from '../../../hooks/streaks/useStreaksUserState';

export function useCurrentStreak() {
  const { isLoading, data } = useStreaksUserState();

  return useMemo(() => {
    if (isLoading) {
      return 'loading';
    }
    if (!data) {
      return;
    }
    const {
      streakDays,
      currentLeague,
      nextLeague,
    } = data;

    let currentLeagueData: {
      title: string;
      streakDays: number;
    } = {
      title: initialLeague.title,
      streakDays: 0,
    };
    let img: {
      src: string;
      srcSet: string | string[];
    } = initialLeague.img;
    let background = initialLeague.gradients.hero;

    if (currentLeague) {
      const localLeague = findLeagueById(currentLeague.id);
      if (!localLeague) {
        console.error(`Unable to find current league with ID ${currentLeague.id}`);
        return;
      }
      currentLeagueData = {
        title: currentLeague.name,
        streakDays: currentLeague.streakDays,
      };
      img = localLeague.img;
      background = localLeague.gradients.hero;
    }

    let nextLeagueData: {
      title: string;
      streakDays: number;
    } | undefined;

    if (nextLeague) {
      if (!findLeagueById(nextLeague.id)) {
        console.error(`Unable to find next league with ID ${nextLeague.id}`);
        return;
      }
      nextLeagueData = {
        title: nextLeague.name,
        streakDays: nextLeague.streakDays,
      };
    }

    return {
      streakDays,
      currentLeague: currentLeagueData,
      nextLeague: nextLeagueData,
      img,
      background,
    };
  }, [isLoading, data]);
}