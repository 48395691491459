import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppViewInitAction } from '../../../hooks/init/useAppViewInitAction';
import { WithColoredUI } from '../../_components/WithColoredUI';

import ApplicationMainScreen from './main';

export default function Application() {
    let { application } = useParams();
    const { data, isLoading, error } = useAppViewInitAction(application!);
    const navigate = useNavigate();

    if (!isLoading && !data) {
        navigate('/');
        return null;
    }

    return (
      <WithColoredUI>
        <ApplicationMainScreen
            data={data}
            isLoading={isLoading}
        />
      </WithColoredUI>
    )
}
