import useSWR from 'swr';

import { getItemFromStorage, setItemToStorage } from '../../../../hooks/hooks';
import { useIsUTCToday } from '../../../hooks/dates/useIsUTCToday';

export function useStatusPanelHidden(
  key: 'streakSaved' | 'taskCompleted',
) {
  const storageKey = `streakStatusPanelHiddenAt${key[0].toUpperCase()}${key.slice(1)}`;
  const isToday = useIsUTCToday();

  const {
    data,
    isLoading,
    mutate,
  } = useSWR(['streakStatusPanelHiddenAt', key], () => {
    return getItemFromStorage(storageKey, 5000)
      .then(v => isToday(new Date(v)))
      .catch(() => false);
  });

  return {
    isHidden: data,
    isLoading,
    hide() {
      mutate(true, { revalidate: false }).catch(console.error);
      setItemToStorage(storageKey, new Date().toString(), 5000).catch(console.error);
    },
  };
}