import { useEffect, useState } from 'react';

/**
 * Returns true if specified value was previously set to any truthy value.
 * @param value
 */
export function useIsSetOnce(value: unknown): boolean {
  const [setOnce, setSetOnce] = useState(false);

  useEffect(() => {
    if (value) {
      setSetOnce(true);
    }
  }, [value]);

  return setOnce;
}