import { createContext } from 'react';

import { createUseContext } from '../createUseContext';

export interface AppStateContextType {
  /**
   * True if the loader is currently displayed.
   */
  isLoaderVisible: boolean;
  /**
   * True if the application loaded initial data to display, and pages can be safely rendered.
   */
  isInitialDataLoaded: boolean;
}

export const AppStateContext = createContext<AppStateContextType | undefined>(undefined);

export const useAppStateContext = createUseContext(AppStateContext);
