import type { ReactNode } from 'react';

import { Footnote } from '../../../../uilib/Typography/Typography';
import { Trans } from 'react-i18next';
import { ChipButton } from '../ChipButton/ChipButton';
import { useUserPocket } from '../../../hooks/pocket/queries/useUserPocket';
import { useIsSetOnce } from '../../../hooks/useIsSetOnce';

import s from './StreakArtifacts.module.scss';

export function StreakArtifacts() {
  const { isLoading, data, error } = useUserPocket();
  const vanishedOnce = useIsSetOnce(error);

  let items: ReactNode;
  if (isLoading && !vanishedOnce) {
    items = (
      <>
        <ChipButton data="loading"/>
        <ChipButton data="loading"/>
      </>
    );
  } else if (data) {
    const { points, lives } = data;

    items = (
      <>
        <ChipButton
          data={{
            title: points,
            subtitle: <Trans i18nKey="PocketPage.StreakArtifactsPts" count={points}/>,
          }}
        />
        <ChipButton
          data={{
            title: lives,
            subtitle: <Trans i18nKey="PocketPage.StreakArtifactsLives" count={lives}/>,
          }}
        />
      </>
    );
  }

  return (
    <div className={s.root}>
      <Footnote className={s.title} weight="semi-bold" caps>
        <Trans i18nKey="PocketPage.StreakArtifactsTitle"/>
      </Footnote>
      <div className={s.items}>
        {items}
      </div>
    </div>
  );
}
