import useSWR, { type SWRResponse } from 'swr';

import { useApiClient } from '../../providers/ApiClientProvider/context';
import { useErrorLog } from '../useErrorLog';

/**
 * Requests streaks daily tasks information.
 */
export function useStreaksDailyTasks(): SWRResponse<{
  main?: {
    id: number;
    app: {
      id: number;
      title?: string | null;
      description?: string | null;
      url?: string | null;
      path?: string | null;
      editorsChoice?: boolean | null;
      webappUrl?: string | null;
      icon: {
        url: string;
        name?: string | null;
      };
    };
    rewards: {
      type?: unknown; // fixme
      value: number;
    }[];
  } | null;
}> {
  const client = useApiClient();
  const response = useSWR('STREAKS_DAILY_TASKS', () => client.getStreaksDailyTasks());

  useErrorLog('Error fetching streaks daily tasks', response.error);

  return response;
}