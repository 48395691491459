import { useUserPocket } from './queries/useUserPocket';

/**
 * Retrieves user points count.
 */
export function useUserPocketPoints() {
  const { isLoading, data } = useUserPocket();
  return isLoading
    ? 'loading'
    : data
      ? data.points
      : undefined
}
