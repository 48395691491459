import { ReactNode, useCallback } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { StreaksPanel } from '../StreaksPanel/StreaksPanel';
import { Footnote, Heading } from '../../../uilib/Typography/Typography';
import { Ripple } from '../../../uilib/Ripple/Ripple';
import System from '../../../services/System';

import s from './StreaksPocketPanel.module.scss';

export function StreaksPocketPanel({
  highlight,
  title,
  text,
  link,
  background,
  className,
  onClick,
}: {
  onClick?(): void;
  className?: string;
  title: ReactNode;
  text: ReactNode;
  link?: string;
  highlight?: boolean;
  background?: {
    className?: string;
    node: ReactNode;
    position?: {
      right?: number | string;
      bottom?: number | string;
      left?: number | string;
      top?: number | string;
    };
  };
}) {
  const navigate = useNavigate();
  const content = (
    <>
      <Heading className={s.title} weight="bold" type="h4">
        {title}
      </Heading>
      {(link || onClick) && (
        <svg
          className={s.arrow}
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9471_3249)">
            <path
              d="M1.5 1L6.5 6L1.5 11"
              stroke="#C8C7CC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_9471_3249">
              <rect width="7" height="12" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>
      )}
      <Footnote
        className={cn(s.text, { [s.textCompressed]: background })}
        weight="medium"
      >
        {text}
      </Footnote>
      {background && (
        <div className={cn(s.background, background.className)} style={background.position}>
          {background.node}
        </div>
      )}
    </>
  );

  const onPanelClick = useCallback(() => {
    System.delayAction(() => {
      link && navigate(link);
      onClick && onClick();
    });
  }, [onClick, link, navigate]);

  return (
    <StreaksPanel
      className={cn(s.root, className, highlight && s.rootHighlight)}
      onClick={onPanelClick}
    >
      {link || onClick
        ? <Ripple className={s.inner}>{content}</Ripple>
        : <div className={s.inner}>{content}</div>}
    </StreaksPanel>
  );
}