import { Trans } from 'react-i18next';
import { ReactNode } from 'react';

import { StreaksPanel } from '../../../../../_components/StreaksPanel/StreaksPanel';
import { StreaksShimmer } from '../../../../../_components/StreaksShimmer/StreaksShimmer';
import { TWAApplicationCard } from '../../../../../../uilib/ApplicationCard/TWAApplicationCard';
import { StreaksTimer } from '../../../../../_components/StreaksTimer/StreaksTimer';
import { Heading } from '../../../../../../uilib/Typography/Typography';
import { useOnDailyTaskAppOpened } from '../../../hooks/useOnDailyTaskAppOpened';

import s from './Task.module.scss';

export interface TaskData {
  id: number;
  title?: string | null;
  description?: string | null;
  editorsChoice?: boolean | null;
  icon: {
    url: string;
    name?: string | null;
  };
  url: string;
}

export function Task({ data }: {
  data: 'loading' | TaskData;
}) {
  const onOpened = useOnDailyTaskAppOpened();

  let titleNode: ReactNode;
  let applicationNode: ReactNode;
  let timerNode: ReactNode;

  if (data === 'loading') {
    titleNode = <StreaksShimmer height={24}/>;
    applicationNode = (
      <div className={s.appShimmerWrap}>
        <StreaksShimmer className={s.appShimmerIcon}/>
        <div className={s.appShimmerInfoWrap}>
          <StreaksShimmer width={90} height={22}/>
          <StreaksShimmer width={140} height={16}/>
          <StreaksShimmer width={60} height={18}/>
        </div>
      </div>
    );
    timerNode = <StreaksShimmer className={s.timerShimmer}/>;
  } else {
    const { icon } = data;

    titleNode = <Trans i18nKey="StreaksPage.DailyTaskTitle"/>;
    applicationNode = (
      <TWAApplicationCard
        title={data.title || undefined}
        editorsChoice={data.editorsChoice || undefined}
        description={data.description || undefined}
        img={{ src: icon.url, name: icon.name || '' }}
        overrideOnClick={onOpened}
        onButtonClick={onOpened}
      />
    );
    timerNode = <StreaksTimer/>;
  }

  return (
    <StreaksPanel className={s.panel}>
      <Heading className={s.title} weight="bold" type="h4">{titleNode}</Heading>
      {applicationNode}
      <div className={s.timerWrapper}>{timerNode}</div>
    </StreaksPanel>
  );
}
