import { useContext, useEffect, useState } from "react";
import { getUserReferralPrograms } from "../../utils/api/requests";
import { ReferralProgramsContext } from "../../dataSource/ReferralProgramsContext";
import { UserReferralProgram } from "../../../types/entities";

export function useReferralPrograms() {
    const checkInData = useContext(ReferralProgramsContext);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [programs, setPrograms] = useState<UserReferralProgram[]>([]);

    useEffect(() => {
        if (isReady) {
            return;
        }

        if (!checkInData.checkInCompleted) {
            return;
        }
        setIsReady(true);

        const load = async () => {
            const controller = new AbortController();
            setAbortController(controller);
            setIsLoading(true);

            try {
                const data = await getUserReferralPrograms(window.Telegram.WebApp.initData, {signal: abortController?.signal});
                if (Array.isArray(data)) {
                    setPrograms(data);
                }
            } catch (error) {
                if (error instanceof DOMException && error.name === 'AbortError') {
                    // aborted, do nothing
                } else if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to load referral programs', error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        load();

        return () => {
            if (abortController) {
                abortController.abort();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkInData.checkInCompleted, isReady]);

    return {
        isLoading,
        programs,
    }
}
