import { array, boolean, date, mixed, number, object, string } from 'yup';

import { HttpClient } from '../../shared/http/HttpClient';

interface SchemaLike {
  validateSync(data: unknown): any;
}

function schemaToValidateFn<S extends SchemaLike>(schema: S): (data: unknown) => ReturnType<S['validateSync']> {
  return (data: unknown) => schema.validateSync(data);
}

export class ApiClient {
  private readonly httpClient: HttpClient;

  constructor(baseUrl: string, initData: string) {
    this.httpClient = new HttpClient(baseUrl, {
      headers: {
        'x-init-data': initData,
      },
    });
  }

  /**
   * Performs a GET request extracting the response data.
   * @param url - URL to send request to.
   * @param schema - validation schema.
   * @private
   */
  private get<S extends SchemaLike>(
    url: string,
    schema: S,
  ): Promise<ReturnType<S['validateSync']>> {
    return this.httpClient.get(url, schemaToValidateFn(schema)).then(r => r.data);
  }

  /**
   * Performs a POST request extracting the response data.
   * @param url - URL to send request to.
   * @param body - request body.
   * @param schema - validation schema.
   * @private
   */
  private post<S extends SchemaLike, Body = unknown>(
    url: string,
    body: Body,
    schema: S,
  ): Promise<ReturnType<S['validateSync']>> {
    return this.httpClient.post(url, body, schemaToValidateFn(schema)).then(r => r.data);
  }

  /**
   * Performs a PUT request extracting the response data.
   * @param url - URL to send request to.
   * @param body - request body.
   * @param schema - validation schema.
   * @private
   */
  private put<S extends SchemaLike, Body = unknown>(
    url: string,
    body: Body,
    schema: S,
  ): Promise<ReturnType<S['validateSync']>> {
    return this.httpClient.put(url, body, schemaToValidateFn(schema)).then(r => r.data);
  }

  /**
   * Completes the daily task.
   * @param id - task identifier.
   */
  async completeStreaksDailyTask(id: number): Promise<void> {
    await this.post(`/streaks/complete-task?id=${id}`, {}, mixed()).then();
  }

  /**
   * Retrieves the user active order.
   */
  getActiveOrder(): Promise<{
    data?: {
      status: 'PENDING' | 'COMPLETED' | 'FAILED' | string;
    } | null
  }> {
    return this.get('/pocket/active-order', object({
      data: object({
        status: string().required(),
      })
        .nullable()
        .optional(),
    }));
  }

  /**
   * @returns Project leagues list.
   */
  async getStreaksLeagues(): Promise<{
    id: number;
    name: string;
    streakDays: number;
    participants: number;
    additionalRewards: boolean;
  }[]> {
    return this
      .get('/streaks/leagues', object({
        items: array()
          .of(
            object({
              id: number().required(),
              name: string().required(),
              streakDays: number().required(),
              participants: number().required(),
              additionalRewards: boolean().required(),
            }),
          )
          .defined(),
      }))
      .then(d => d.items);
  }

  /**
   * Returns the current user state in streaks context.
   */
  async getStreaksUserState(): Promise<{
    streakDays: number;
    latestCompletedTaskDate?: Date | null;
    daysToNextLeague: number;
    currentLeague?: {
      id: number;
      name: string;
      streakDays: number;
    } | null;
    nextLeague?: {
      id: number;
      name: string;
      streakDays: number;
    } | null
  }> {
    return this.get('/streaks/user-state', object({
      streakDays: number().defined(),
      latestCompletedTaskDate: date().nullable(),
      daysToNextLeague: number().defined(),
      currentLeague: object({
        id: number().defined(),
        name: string().defined(),
        streakDays: number().defined(),
      })
        .nullable()
        .optional(),
      nextLeague: object({
        id: number().defined(),
        name: string().defined(),
        streakDays: number().defined(),
      })
        .nullable()
        .optional(),
    }));
  }

  /**
   * Returns user daily tasks.
   */
  async getStreaksDailyTasks(): Promise<{
    main?: {
      id: number;
      app: {
        id: number;
        title?: string | null;
        description?: string | null;
        url?: string | null;
        editorsChoice?: boolean | null;
        webappUrl?: string | null;
        icon: {
          url: string;
          name?: string | null;
        };
      };
      rewards: {
        type?: unknown; // fixme
        value: number;
      }[];
    } | null;
  }> {
    const { main } = await this.get('/streaks/daily-tasks', object({
      main: object({
        id: number().defined(),
        app: object({
          id: number().defined(),
          attributes: object({
            title: string().nullable(),
            description: string().nullable(),
            url: string().nullable(),
            editors_choice: boolean().nullable(),
            webapp_url: string().nullable(),
            icon: object({
              data: object({
                attributes: object({
                  url: string().defined(),
                  name: string(),
                }),
              }),
            }),
          }),
        }),
        rewards: array()
          .of(
            object({
              // fixme: PropertyType from @prisma/client?
              type: mixed(),
              value: number().defined(),
            }),
          )
          .defined(),
      })
        .nullable()
        .optional(),
    }));
    if (!main) {
      return {};
    }

    const {
      app: {
        id,
        attributes: {
          url,
          icon: {
            data: {
              attributes: icon,
            },
          },
          title,
          webapp_url: webappUrl,
          description,
          editors_choice: editorsChoice,
        },
      },
      id: taskId,
      rewards,
    } = main;
    return {
      main: {
        app: {
          id,
          title,
          url,
          icon,
          webappUrl,
          description,
          editorsChoice,
        },
        id: taskId,
        rewards,
      },
    };
  }

  /**
   * Retrieves the user pocket information.
   */
  getUserPocket(): Promise<{
    points: number;
    boosts: number;
    lives: number;
    spins: number;
    nftTickets: number;
  }> {
    return this.get('/pocket/user', object({
      points: number().required(),
      boosts: number().required(),
      lives: number().required(),
      spins: number().required(),
      nftTickets: number().required(),
    }));
  }

  /**
   * Retrieves all known pocket collections.
   */
  getPocketCollections(): Promise<{
    collectionId: string;
    name: string;
    description: string;
    price: number;
    // FIXME: Use type: string
    type?: string;
    // type: string;
  }[]> {
    return this.get('/pocket/collections', array().of(
      object({
        collectionId: string().required(),
        name: string().required(),
        description: string().required(),
        price: number().required(),
        // FIXME: string().required()
        type: string(),
        // type: string().required(),
      }),
    ).required());
  }

  /**
   * Retrieves a specific collection.
   * @param collectionId - collection identifier.
   */
  getPocketCollection(collectionId?: string): Promise<{
    id: number;
    collectionId: string;
    name: string;
    description: string;
    price: number;
    previews?: Array<{
      url: string,
      resolution: string,
    }>,
  }> {
    return this.get(`/pocket/collection?id=${collectionId}`,
      object({
        id: number().required(),
        collectionId: string().required(),
        name: string().required(),
        description: string().required(),
        previews: array().of(
          object({
            url: string().required(),
            resolution: string().required(),
          }),
        ).optional(),
        price: number().required(),
      }).required(),
    );
  }

  /**
   * Returns the current user streaks leaderboard rank information.
   */
  getStreaksLeaderboardRank(): Promise<{ rank: number }> {
    return this.get('/leaderboard/rank', object({
      rank: number().required(),
    }));
  }

  /**
   * Restores the user streak using the lives purchase transaction ID.
   * @param transactionId - transaction ID.
   */
  restoreStreak(transactionId: number): Promise<void> {
    return this.post('/streaks/restore-streak', { transactionId }, mixed()).then();
  }

  /**
   * Converts lives to transaction.
   * @param livesCount - count of lives to pack.
   */
  livesToTransaction(livesCount: number): Promise<{ transactionId: number }> {
    return this.post('/pocket/lives-to-transaction', { livesCount }, object({
      transactionId: number().required(),
    }));
  }

  /**
   * Purchases specified pocket items and returns transaction information.
   * @param wallet - wallet address.
   * @param items - purchased items.
   */
  purchasePocketItems(wallet: string, items: {
    collectionId: string,
    count: number
  }[]): Promise<void> {
    return this.post('/pocket/purchase', { wallet, items }, mixed()).then();
  }

  /**
   * Registers the user wallet in our system.
   * @param address - wallet address.
   */
  registerWallet(address: string): Promise<void> {
    return this.put('/pocket/register-wallet', { address }, mixed()).then();
  }

  /**
   * Signs the user in.
   */
  signIn(): Promise<void> {
    return this.post('/auth/sign-in', {}, mixed()).then();
  }
}