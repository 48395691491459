import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';

import { Heading } from '../../../../uilib/Typography/Typography';
import { Artifact, ArtifactShimmer } from './Artifact/Artifact';
import { findArtifact } from '../../../pocket/artifacts';
import { usePocketCollections } from '../../../hooks/pocket/queries/usePocketCollections';
import { useTonConnect } from '../../../providers/TonConnectProvider/context';
import { useUserPocketPoints } from '../../../hooks/pocket/useUserPocketPoints';
import { useToolkit } from './useToolkit';

import s from './Artifacts.module.scss';

export function Artifacts() {
  const { wallet } = useTonConnect();
  const { t } = useTranslation();
  const { data } = usePocketCollections();
  const userPoints = useUserPocketPoints();
  const { isLoading, totalPrice, getCountSelected, changeCountSelected } = useToolkit();

  let content: ReactNode;
  if (data) {
    content = data
      .filter((v): v is Exclude<typeof v, undefined> => v !== undefined)
      // TODO: We temporarily hide all items except LIVES. We should return them back in the
      //  future.
      // FIXME: collectionId?
      .filter(v => v.type === 'LIVES' || v.collectionId === '0EGCva0vzQVay7SX_FUmL_UrVGr1c2JykZRijmDpTx38Sdpv')
      .map((item, idx) => {
        const artifact = findArtifact(item.type, item.collectionId);
        const { collectionId } = item;

        return artifact ? (
          <Artifact
            key={idx}
            id={item.collectionId}
            title={t(artifact.titleKey)}
            description={t(artifact.shortDescriptionKey)}
            price={item.price}
            img={artifact.img.small}
            countSelected={getCountSelected(collectionId)}
            onCountChanged={changeCountSelected}
            canRemove={!isLoading}
            canAdd={
              typeof userPoints === 'number'
              && (totalPrice + item.price) <= userPoints
              && !!wallet && !isLoading
            }
          />
        ) : undefined;
      });
  } else {
    content = new Array(4).fill(null).map((_, idx) => (
      <ArtifactShimmer key={idx}/>
    ));
  }

  return (
    <div className={s.root}>
      <Heading weight="bold" type="h4">
        <Trans i18nKey="PocketPage.ArtifactsTitle"/>
      </Heading>
      <div className={s.items}>
        {content}
      </div>
    </div>
  );
}
