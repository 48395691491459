import { useMemo } from 'react';

import { useNow } from './useNow';
import { toUTCDay } from '../../utils/dates';

/**
 * @returns the beginning of the current date in UTC timezone.
 */
export function useUTCToday(): number {
  const time = useNow();

  return useMemo(() => toUTCDay(time), [time]);
}