import type { ReactNode } from 'react';
import cn from 'classnames';

import { Footnote } from '../../../uilib/Typography/Typography';

import s from './Progressbar.module.scss';

function StarSvg() {
  return (
    <svg
      className={s.star}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="url(#a)"
        fillOpacity=".5"
        d="M2.75 13.547c-.299-.223-.357-.598-.193-1.084l1.19-3.533L.71 6.756C.295 6.457.107 6.117.23 5.76c.123-.352.462-.528.972-.522l3.727.03L6.06 1.71c.159-.498.422-.762.797-.762s.633.264.791.762l1.137 3.557 3.715-.03c.521-.006.855.17.979.522.117.357-.06.697-.481.996L9.963 8.93l1.19 3.533c.163.486.105.861-.194 1.084-.305.234-.674.158-1.096-.147l-3.006-2.209-3.011 2.21c-.422.304-.791.38-1.096.146"
      />
      <defs>
        <linearGradient
          id="a"
          x1="-1"
          x2="15"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"/>
          <stop offset="1" stopColor="#fff" stopOpacity=".65"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Progressbar({ from, current, to, footer = {} }: {
  current: number;
  from: number;
  to: number;
  footer?: {
    left?: ReactNode;
    right?: ReactNode;
  };
}) {
  const progress = (current - from) / (to - from);
  const { right, left } = footer;

  return (
    <div>
      <div className={s.progressbar}>
        {progress > 0 && (
          <div
            className={cn(s.progressbarItem, s.progressbarItemCurrent)}
            style={{ flexGrow: progress }}
          />
        )}
        <StarSvg/>
        <div
          className={s.progressbarItem}
          style={{ flexGrow: 1 - progress }}
        />
      </div>
      {Boolean(left || right) && (
        <Footnote className={s.footer} weight="medium">
          <span>{left}</span>
          <span>{right}</span>
        </Footnote>
      )}
    </div>
  );
}