import { useCallback } from 'react';

import { useUTCToday } from './useUTCToday';
import { toUTCDay } from '../../utils/dates';

/**
 * @returns Function returning true if the passed date is today.
 */
export function useIsUTCToday(): (date: Date) => boolean {
  const today = useUTCToday();

  return useCallback((date: Date) => today === toUTCDay(date), [today]);
}