import { useMemo } from 'react';

import { findLeagueById } from '../../../leagues/leagues';
import { useStreaksLeagues } from '../../../hooks/streaks/useStreaksLeagues';
import type { LeagueData } from '../components/Leagues/League/League';

export function useLeagues() {
  const { data, isLoading } = useStreaksLeagues();

  return useMemo(() => {
    if (isLoading) {
      return 'loading';
    }
    if (!data) {
      return;
    }
    return data.reduce<LeagueData[]>((acc, l) => {
      const league = findLeagueById(l.id);
      if (league) {
        acc.push({
          img: league.img,
          background: league.gradients.list,
          streakDays: l.streakDays,
          participants: l.participants,
          title: l.name,
          additionalRewards: l.additionalRewards,
        });
      }
      return acc;
    }, []);
  }, [isLoading, data]);
}