import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RECENT_CATEGORIES_PATH } from '../../utils/constatns';

export function NavigationEvents() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    BackButton: backButton,
    SettingsButton: settingsButton,
  } = window.Telegram.WebApp;

  useEffect(() => {
    if (location.pathname === '/') {
      backButton.hide();
    } else {
      backButton.show();
    }
  }, [location, backButton]);

  useEffect(() => {
    function handler() {
      if (location.pathname === RECENT_CATEGORIES_PATH) {
        return navigate('/');
      }
      navigate(-1);
    }

    backButton.onClick(handler);
    return () => {
      backButton.offClick(handler);
    };
  }, [backButton, location]);

  useEffect(() => {
    function handler() {
      navigate('/settings');
    }

    settingsButton.show();
    settingsButton.onClick(handler);
    return () => {
      settingsButton.offClick(handler);
    }
  }, [settingsButton, navigate]);

  return null;
}
