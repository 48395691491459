import { ReactNode } from 'react';
import cn from 'classnames';

import { StreaksShimmer } from '../../../_components/StreaksShimmer/StreaksShimmer';
import { Heading, SubHeadline } from '../../../../uilib/Typography/Typography';
import { Ripple } from '../../../../uilib/Ripple/Ripple';

import s from './ChipButton.module.scss';

export function ChipButton({ data }: {
  data: 'loading' | {
    title: ReactNode;
    subtitle: ReactNode;
    onClick?(): void;
  };
}) {
  // return data !== 'loading' && data.onClick ? (
  //   <Ripple className={s.ripple} onClick={data.onClick}>
  //     {inner}
  //   </Ripple>
  // ) : inner;
  let inner: ReactNode;
  if (data === 'loading') {
    inner = (
      <>
        <StreaksShimmer height={28} width={50}/>
        <StreaksShimmer height={18} width={40}/>
      </>
    );
  } else {
    const content = (
      <>
        <Heading weight="bold" type="h3">{data.title}</Heading>
        <SubHeadline
          className={s.subtitle}
          weight="regular"
          level={2}
        >{data.subtitle}</SubHeadline>
      </>
    );

    inner = data.onClick ? (
      <Ripple className={s.ripple} onClick={data.onClick}>
        {content}
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.06885 6.41699C7.06885 6.52702 7.04769 6.62858 7.00537 6.72168C6.96305 6.81478 6.89958 6.90365 6.81494 6.98828L1.87012 11.8252C1.73047 11.9691 1.55697 12.041 1.34961 12.041C1.21419 12.041 1.08936 12.0072 0.975098 11.9395C0.86084 11.876 0.769857 11.7871 0.702148 11.6729C0.638672 11.5628 0.606934 11.4401 0.606934 11.3047C0.606934 11.1016 0.683105 10.9217 0.835449 10.7651L5.2915 6.41699L0.835449 2.06885C0.683105 1.91227 0.606934 1.73454 0.606934 1.53564C0.606934 1.396 0.638672 1.27116 0.702148 1.16113C0.769857 1.05111 0.86084 0.964355 0.975098 0.900879C1.08936 0.833171 1.21419 0.799316 1.34961 0.799316C1.55697 0.799316 1.73047 0.869141 1.87012 1.00879L6.81494 5.8457C6.89958 5.93034 6.96305 6.01921 7.00537 6.1123C7.04769 6.2054 7.06885 6.30697 7.06885 6.41699Z"
            fill="white"
            fillOpacity="0.6"
          />
        </svg>
      </Ripple>
    ) : content;
  }

  return (
    <div className={cn(s.root, data !== 'loading' && data.onClick && s.rootClickable)}>
      {inner}
    </div>
  );
}