import { useCallback } from 'react';
import { toUTCDay } from '../../utils/dates';
import { useUTCToday } from './useUTCToday';

/**
 * @returns Function returning count of days elapsed since today in UTC+0 timezone.
 */
export function useUTCDaysDiff(): (date: Date) => number {
  const today = useUTCToday();

  return useCallback((date) => {
    return ((toUTCDay(date) - today) / (24 * 60 * 60 * 1000)) | 0;
  }, [today]);
}