import useSWR, { type SWRResponse } from 'swr';

import { useApiClient } from '../../providers/ApiClientProvider/context';
import { useErrorLog } from '../useErrorLog';

/**
 * Requests streaks user state.
 */
export function useStreaksUserState(): SWRResponse<{
  streakDays: number;
  daysToNextLeague: number;
  latestCompletedTaskDate?: Date | null;
  currentLeague?: {
    id: number;
    name: string;
    streakDays: number;
  } | null;
  nextLeague?: {
    id: number;
    name: string;
    streakDays: number;
  } | null;
}> {
  const client = useApiClient();
  const response = useSWR('STREAKS_USER_STATE', () => client.getStreaksUserState());

  useErrorLog('Error fetching user streaks state', response.error);

  return response;
}