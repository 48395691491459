import { BlockData, MainPageBlockData } from '../../../types/Blocks'
import { NavigationConfig, TWACategory } from '../../../types/entities';
import { getEntity } from './entities';

export async function getNavigationConfig(): Promise<Array<TWACategory>> {
    const navigationConfig = await getEntity<NavigationConfig>(
        'navigation',
        {
            populate: [
                'categories',
                'categories.icon'
            ],
        },
    );

    return navigationConfig?.data?.attributes.categories.data ?? [];
}

function getBlocksPageConfigName() {
    try {
        if (window.location.search.includes('?source=dmo-page-with-block')) {
            return 'dmo-page-with-block';
        }
    } catch (e) {
    }

    // todo #important @barinbritva rollback to `pagewithblocks`
    return 'pagewithblocks';
}

export async function getMainPageBlockConfig(): Promise<Array<BlockData>> {
    const mainPageBlocksConfig = await getEntity<MainPageBlockData>(
        getBlocksPageConfigName(),
        {
            populate: [
                'blocks',
                'blocks.feature',
                'blocks.feature.banner',
                'blocks.feature.banner.tags',
                'blocks.feature.banner.tags.title',
                'blocks.feature.banner.tags.title.description',
                'blocks.feature.banner.tags.title.background',
                'blocks.feature.entity',
                'blocks.feature.entity.url',
                'blocks.feature.entity.url.label',
                'blocks.feature.application.icon',
                'blocks.feature.application.categories',
                'blocks.feature.application.poster',
                'blocks.feature.application.platforms',
                'blocks.feature.application.path',
                'blocks.feature.application.url',
                'blocks.feature.application.webapp_url',
                'blocks.category',
                'blocks.category.icon',
                'blocks.category.title',
                'blocks.category.description',
                'blocks.category.applications',
                'blocks.category.applications.ecosystem',
                'blocks.category.applications.platforms',
                'blocks.category.applications.platforms.title',
                'blocks.category.alert',
                'blocks.category.alert.title',
                'blocks.category.alert.content',
                'blocks.category.alert.key',
                'blocks.category.alert.icon',
            ],
        },
    );

    return mainPageBlocksConfig?.data?.attributes?.blocks?.data ?? []
}
