import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';

import { useApiClient } from '../../../providers/ApiClientProvider/context';

/**
 * @returns Mutation to register wallet.
 * @param options - additional options.
 */
export function useRegisterWallet(
  options?: SWRMutationConfiguration<void, unknown, string, string>,
) {
  const client = useApiClient();
  return useSWRMutation('REGISTER_WALLET', (_, { arg }) => client.registerWallet(arg), {
    ...options,
    onError(err, key, config) {
      console.error('Error registering wallet', err);
      options && options.onError && options.onError(err, key, config);
    },
  });
}