import type { ReactNode } from 'react';
import { Trans } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { StreaksShimmer } from '../../../../_components/StreaksShimmer/StreaksShimmer';
import { StreaksPanel } from '../../../../_components/StreaksPanel/StreaksPanel';
import { Footnote, Heading, SubHeadline } from '../../../../../uilib/Typography/Typography';
import { Button } from '../../Button/Button';

import s from './Artifact.module.scss';

export function ArtifactShimmer() {
  return (
    <StreaksPanel className={s.root}>
      <div className={s.imageWrap}>
        <StreaksShimmer height="100%"/>
      </div>
      <div className={s.content} style={{ paddingBottom: 12 }}>
        <StreaksShimmer style={{ marginBottom: 4 }} width="95%" height={24}/>
        <StreaksShimmer style={{ marginBottom: 4 }} width="70%" height={16}/>
        <StreaksShimmer style={{ marginBottom: 22 }} width="30%" height={16}/>
        <StreaksShimmer width="100%" height={30} rounded/>
      </div>
    </StreaksPanel>
  );
}

export function Artifact({
  canAdd,
  canRemove,
  id,
  countSelected,
  onCountChanged,
  title,
  price,
  img,
  description,
}: {
  id: string;
  title: string;
  description: ReactNode;
  price: number;
  img: {
    src: string;
    srcSet: string;
    width: number;
    height: number;
    background: {
      color: string;
      src: string;
      width: number;
      height: number;
      deltaX: number;
      deltaY: number;
    };
  };
  canRemove: boolean;
  canAdd: boolean;
  countSelected: number;
  onCountChanged(id: string, isAdd: boolean): void;
}) {
  // const navigate = useNavigate();
  const { background } = img;

  return (
    <StreaksPanel className={s.root}>
      {/*TODO: Disabled cause have no time*/}
      {/*<Ripple*/}
      {/*  className={s.inner}*/}
      {/*  onClick={() => {*/}
      {/*    System.delayAction(() => {*/}
      {/*      navigate(`/pocket/${id}`);*/}
      {/*    });*/}
      {/*  }}*/}
      {/*>*/}
      <div className={s.inner}>
        <div
          className={s.imageWrap}
          style={{ background: background.color }}
          onContextMenu={e => {
            e.preventDefault();
          }}
        >
          <img
            className={s.imageBg}
            alt=""
            src={background.src}
            style={{
              width: background.width,
              height: background.height,
              transform: `translate3d(calc(-50% + ${background.deltaX}px), calc(-50% + ${background.deltaY}px), 0)`,
            }}
          />
          <img
            {...img}
            className={s.image}
            alt={title}
            style={{ width: img.width, height: img.height }}
          />
        </div>
        <div className={s.content}>
          <div>
            <Heading weight="semi-bold" type="h4">{title}</Heading>
            <Footnote className={s.description} weight="regular">
              {description}
            </Footnote>
          </div>
        </div>
      </div>
      {/*</Ripple>*/}
      <div className={s.control}>
        {countSelected ? (
          <SubHeadline weight="semi-bold" level={1} className={s.countControls}>
            <Button
              className={cn(s.countControl, s.countControlAdd)}
              onClick={() => onCountChanged(id, false)}
              disabled={!canRemove}
            >
              <svg
                width="13"
                height="3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.24121 2.66406C1.08008 2.66406 0.928711 2.62256 0.787109 2.53955C0.645508 2.45166 0.530762 2.33691 0.442871 2.19531C0.35498 2.04883 0.311035 1.89014 0.311035 1.71924C0.311035 1.54834 0.35498 1.39209 0.442871 1.25049C0.530762 1.10889 0.645508 0.996582 0.787109 0.913574C0.928711 0.825684 1.08008 0.781738 1.24121 0.781738H11.7515C11.9175 0.781738 12.0713 0.825684 12.2129 0.913574C12.3545 0.996582 12.4668 1.10889 12.5498 1.25049C12.6377 1.39209 12.6816 1.54834 12.6816 1.71924C12.6816 1.89014 12.6377 2.04883 12.5498 2.19531C12.4668 2.33691 12.3545 2.45166 12.2129 2.53955C12.0713 2.62256 11.9175 2.66406 11.7515 2.66406H1.24121Z"
                  fill="currentColor"
                />
              </svg>
            </Button>
            <span className={s.countSelected}>{countSelected}</span>
            <Button
              className={cn(s.countControl, s.countControlRemove)}
              onClick={() => onCountChanged(id, true)}
              disabled={!canAdd}
            >
              <svg
                width="14"
                height="13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.803711 6.71191C0.803711 6.45312 0.896484 6.23096 1.08203 6.04541C1.26758 5.85986 1.48975 5.76709 1.74854 5.76709H6.0625V1.46045C6.0625 1.20166 6.15283 0.979492 6.3335 0.793945C6.51416 0.608398 6.73633 0.515625 7 0.515625C7.25879 0.515625 7.48096 0.608398 7.6665 0.793945C7.85205 0.979492 7.94482 1.20166 7.94482 1.46045V5.76709H12.2588C12.5127 5.76709 12.7324 5.85986 12.918 6.04541C13.1035 6.23096 13.1963 6.45312 13.1963 6.71191C13.1963 6.97559 13.1035 7.2002 12.918 7.38574C12.7324 7.56641 12.5127 7.65674 12.2588 7.65674H7.94482V11.9707C7.94482 12.2246 7.85205 12.4443 7.6665 12.6299C7.48096 12.8154 7.25879 12.9082 7 12.9082C6.73633 12.9082 6.51416 12.8154 6.3335 12.6299C6.15283 12.4443 6.0625 12.2246 6.0625 11.9707V7.65674H1.74854C1.48975 7.65674 1.26758 7.56641 1.08203 7.38574C0.896484 7.2002 0.803711 6.97559 0.803711 6.71191Z"
                  fill="currentColor"
                />
              </svg>
            </Button>
          </SubHeadline>
        ) : (
          <Button
            className={s.button}
            disabled={!canAdd}
            onClick={() => onCountChanged(id, true)}
            rounded
          >
            <SubHeadline weight="semi-bold" level={1}>
              <Trans i18nKey="PocketPage.ArtifactPricePoints" count={price}/>
            </SubHeadline>
          </Button>
        )}
      </div>
    </StreaksPanel>
  );
}