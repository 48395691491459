import { useMemo, useState } from 'react';

import {
  type PocketCollection,
  usePocketCollections,
} from '../../../hooks/pocket/queries/usePocketCollections';

type SelectedItems = Record<string, { artifact: PocketCollection; count: number }>;

/**
 * Hook providing utilities to work with the selected artifacts.
 */
export function useSelectedItems() {
  const { data: collections } = usePocketCollections();
  const [selected, setSelected] = useState<SelectedItems>({});

  return {
    totalPrice: useMemo(() => {
      return Object.values(selected).reduce<number>((acc, { count, artifact }) => {
        return acc + count * artifact.price;
      }, 0);
    }, [selected]),
    changeCountSelected(id: string, add: boolean) {
      if (!collections) {
        return;
      }

      let current = selected[id];
      if (current) {
        if (add) {
          current.count++;
        } else {
          current.count = Math.max(current.count - 1, 0);
        }
      } else {
        current = {
          artifact: collections.find(item => item.collectionId === id)!,
          count: 1,
        };
      }

      // TODO: For some reason, using setSelectedItems(state => { ... }) works improperly. Passed
      //  hooks is being called twice.
      setSelected({ ...selected, [id]: current });
    },
    getCountSelected(id: string) {
      return id in selected ? selected[id].count : 0;
    },
    getSelectedItems() {
      return Object.entries(selected).reduce<{ collectionId: string; count: number }[]>(
        (acc, [collectionId, { count }]) => {
          count && acc.push({ collectionId, count });
          return acc;
        }, [],
      );
    },
    resetSelected() {
      setSelected({});
    },
  };
}