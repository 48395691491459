import { PropsWithChildren, useEffect } from 'react';

import { useAppStateContext } from '../providers/AppStateProvider/context';

/**
 * Component which colorizes Mini Apps UI elements.
 */
export function WithColoredUI({
  children,
  color,
  headerColor,
  backgroundColor,
  bottomBarColor,
}: PropsWithChildren<{
  /**
   * Allows specifying a single color for all UI components.
   */
  color?: string;
  headerColor?: string;
  backgroundColor?: string;
  bottomBarColor?: string;
}>) {
  const { isLoaderVisible } = useAppStateContext();

  useEffect(() => {
    // We are not applying any visual changes until the loader is hidden.
    if (isLoaderVisible) {
      return;
    }
    const {
      setHeaderColor,
      setBackgroundColor,
      setBottomBarColor,
      isVersionAtLeast,
      themeParams,
    } = window.Telegram.WebApp;

    let finalColor = color;
    let finalHeaderColor: string | undefined;
    let finalBackgroundColor: string | undefined;
    let finalBottomBarColor: string | undefined;

    // If there are no colors specified at all, we want everything to have
    // section_bg_color color (our default application color).
    if (!headerColor && !backgroundColor && !bottomBarColor && !finalColor) {
      finalColor = themeParams.section_bg_color;
    }

    if (finalColor) {
      finalHeaderColor = finalColor;
      finalBackgroundColor = finalColor;
      finalBottomBarColor = finalColor;
    }

    finalHeaderColor && setHeaderColor(finalHeaderColor);
    finalBackgroundColor && setBackgroundColor(finalBackgroundColor);
    finalBottomBarColor && isVersionAtLeast('7.10') && setBottomBarColor(finalBottomBarColor);
  }, [headerColor, backgroundColor, bottomBarColor, color, isLoaderVisible]);

  return <>{children}</>;
}