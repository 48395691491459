import useSWR, { type SWRResponse } from 'swr';

import { useApiClient } from '../../providers/ApiClientProvider/context';
import { useErrorLog } from '../useErrorLog';

/**
 * Requests streaks leagues information.
 */
export function useStreaksLeagues(): SWRResponse<{
  id: number;
  name: string;
  streakDays: number;
  participants: number;
  additionalRewards: boolean;
}[]> {
  const client = useApiClient();
  const response = useSWR('STREAKS_LEAGUES', () => client.getStreaksLeagues());

  useErrorLog('Error fetching streaks leagues', response.error);

  return response;
}