import { Section } from '../Section/Section';
import { createSrcSet } from '../../../../../utils/createSrcSet';
import { Streak } from './Streak/Streak';
import { StreaksShimmer } from '../../../../_components/StreaksShimmer/StreaksShimmer';
import { StreaksAnimatePresence } from '../../../../_components/StreaksAnimatePresence';
import { useCurrentStreak } from '../../hooks/useCurrentStreak';
import { useIsSetOnce } from '../../../../hooks/useIsSetOnce';

import s from './CurrentStreak.module.scss';

export function CurrentStreak() {
  const data = useCurrentStreak();
  const vanishedOnce = useIsSetOnce(!data);

  return (
    <StreaksAnimatePresence>
      {(data && (data !== 'loading' || !vanishedOnce)) && (
        <Section
          className={s.root}
          style={{ background: data === 'loading' ? undefined : data.background }}
        >
          <div className={s.content}>
            {data === 'loading'
              ? (
                <StreaksShimmer
                  className={s.imgShimmer}
                  width={150}
                  height={180}
                />
              ) : (
                <img
                  className={s.img}
                  alt={data.currentLeague.title}
                  src={data.img.src}
                  srcSet={Array.isArray(data.img.srcSet)
                    ? createSrcSet(data.img.srcSet)
                    : data.img.srcSet}
                  height={200}
                  onContextMenu={e => {
                    e.preventDefault()
                  }}
                />
              )}
            <Streak data={data}/>
          </div>
        </Section>
      )}
    </StreaksAnimatePresence>
  );
}