import { useEffect, useState } from 'react';

import { useServerTimeContext } from '../../providers/ServerTimeProvider/context';

/**
 * Returns current time. The value is being updated each second.
 */
export function useNow(): Date {
  const { getCurrentTime } = useServerTimeContext();
  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  return currentTime;
}