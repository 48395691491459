import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { useTonConnect } from '../../../providers/TonConnectProvider/context';

/**
 * Hook controlling the Main Button state.
 * @param isLoading
 * @param price
 * @param purchase
 */
export function useMainButton({ isLoading, totalPrice, purchase }: {
  isLoading: boolean;
  totalPrice: number;
  purchase(): void;
}) {
  const {
    wallet,
    modal: {
      open: openTonConnectModal,
      state: tonConnectModalState,
    },
  } = useTonConnect();
  const { t } = useTranslation();

  const isTonConnectModalOpened = tonConnectModalState.status === 'opened';

  // Whenever wallet state, TON Connect modal state or price changes, we should
  // actualize the Main Button state.
  useEffect(() => {
    const mb = window.Telegram.WebApp.MainButton;

    if (isLoading) {
      mb.setParams({
        has_shine_effect: true,
        is_active: false,
        is_visible: true,
        text: totalPrice
          ? t('PocketPage.BuyItemsButton', { count: totalPrice })
          : t('PocketPage.LoadingActiveOrderButton'),
      });
      mb.showProgress();
      return;
    }

    // We are not loading anything, the loader should be hidden.
    mb.hideProgress();

    // When showing the wallet connect modal, we are hiding the Main Button for better UI.
    if (isTonConnectModalOpened) {
      mb.setParams({ is_visible: false });
      return;
    }

    // Wallet is not connected. Offer to connect it using the Main Button.
    if (!wallet) {
      mb.setParams({
        text: t('PocketPage.ConnectWalletButton'),
        is_active: true,
        is_visible: true,
        has_shine_effect: true,
      });
      mb.onClick(openTonConnectModal);
      return () => {
        mb.offClick(openTonConnectModal);
      };
    }

    // Wallet is connected, display the selected items price.
    if (!totalPrice) {
      mb.setParams({ is_visible: false });
      return;
    }

    mb.setParams({
      has_shine_effect: true,
      is_active: true,
      is_visible: true,
      text: t('PocketPage.BuyItemsButton', { count: totalPrice }),
    });
    mb.onClick(purchase);
    return () => {
      mb.offClick(purchase);
    };
  }, [
    wallet,
    isTonConnectModalOpened,
    openTonConnectModal,
    t,
    totalPrice,
    isLoading,
    purchase,
  ]);

  // TODO: Should probably be configured by each component itself.
  useEffect(() => {
    return () => {
      window.Telegram.WebApp.MainButton.hide();
    };
  }, []);
}