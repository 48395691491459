import { Trans } from 'react-i18next';

import { Body } from '../../../uilib/Typography/Typography';

import s from './StreaksLottery.module.scss';

export function StreaksLottery() {
  return (
    <Body className={s.root} weight="semi-bold">
      <Trans i18nKey="StreaksLottery.Text"/>
    </Body>
  );
}