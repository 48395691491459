import React from 'react';
import ReactDOM from 'react-dom/client';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';

import { initSentry } from './utils/init/sentry';
import { Root } from './app/_components/Root';

// Mock the environment in case, we are outside Telegram.
import './mockEnv.ts';

try {
  if (
    process.env.NODE_ENV === 'development'
    || retrieveLaunchParams().startParam === 'debug'
  ) {
    // Add Eruda if needed.
    import('eruda')
      .then(({ default: eruda }) => {
        eruda.init();
        eruda.position({ x: 10, y: 100 });
      })
      .catch(console.error);
  }
} catch (e) {
}

initSentry();

const tonConnectReturnBaseUrl = process.env.REACT_APP_TON_CONNECT_RETURN_BASE_URL;
// TODO: This should not work like that. There should be a proper error handling.
if (!tonConnectReturnBaseUrl) {
  throw new Error('REACT_APP_TON_CONNECT_RETURN_BASE_URL is missing');
}

const tonConnectManifestUrl = process.env.REACT_APP_TON_CONNECT_MANIFEST_URL;
// TODO: This should not work like that. There should be a proper error handling.
if (!tonConnectManifestUrl) {
  throw new Error('REACT_APP_TON_CONNECT_MANIFEST_URL is missing');
}

const startParam = new URLSearchParams(window.location.search)
  .get('tgWebAppStartParam') || '';
if (startParam === 'streaks') {
  window.history.replaceState(null, '', '/twa');
  window.history.pushState(null, '', '/twa/streaks');
} else {
  const match = startParam.match(/^page_(.+)$/);
  if (match) {
    try {
      const page = atob(match[1]);
      window.history.replaceState(null, '', '/twa');
      window.history.pushState(null, '', `/twa${page}`);
    } catch {
    }
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Root
      apiBaseUrl={new URL('/api/', window.location.href).toString()}
      tonConnectReturnBaseUrl={tonConnectReturnBaseUrl}
      tonConnectManifestUrl={tonConnectManifestUrl}
    />
  </React.StrictMode>,
);
