import React, { useEffect, useMemo, useState } from "react";
import { checkInReferralPrograms } from "../utils/api/requests";
import { StartAppDataAdapter } from "../hooks/useDeeplinkMiddleware/getStartAppData";
import { useCloudStorage } from "../hooks/hooks";

export interface ReferralProgramsContextData {
    checkInCompleted: boolean;
}

export const ReferralProgramsContext = React.createContext<ReferralProgramsContextData>({checkInCompleted: false});

export interface ReferralProgramsProviderProps {
    children: React.ReactNode;
}

const REFERRAL_CODE = 'referral_programs_referral_code_v1';

export const ReferralProgramsProvider: React.FC<ReferralProgramsProviderProps> = ({children}) => {
    const cloudStorage = useCloudStorage();
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [checkInCompleted, setCheckInCompleted] = React.useState(false);
    const refCodeFromUrl = useMemo(() => {
        const param = new StartAppDataAdapter().get();
        if (param && param.indexOf('ref_') === 0) {
            return param.split('_').slice(1).join('_');
        }
    }, []);

    useEffect(() => {
        const checkIn = async () => {
            const refCodeFromStorage = await cloudStorage.getItemFromStorage(REFERRAL_CODE);
            if (!refCodeFromStorage && refCodeFromUrl) {
                await cloudStorage.setItemToStorage(REFERRAL_CODE, refCodeFromUrl);
            }

            const controller = new AbortController();
            setAbortController(controller);

            try {
                await checkInReferralPrograms(
                    {
                        initData: window.Telegram.WebApp.initData,
                        referralCode: refCodeFromStorage || refCodeFromUrl
                    },
                    {signal: controller.signal}
                );
            } catch (error) {
                if (error instanceof DOMException && error.name === 'AbortError') {
                    // aborted, do nothing
                } else if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to check in referral programs', error);
                }
            } finally {
                setCheckInCompleted(true);
            }
        }

        checkIn();

        return () => {
            if (abortController) {
                abortController.abort();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ReferralProgramsContext.Provider value={{checkInCompleted: checkInCompleted}}>
        {children}
    </ReferralProgramsContext.Provider>
}
