import { Trans, useTranslation } from 'react-i18next';

import { StatusPanel } from './StatusPanel/StatusPanel';
import { StreaksButton } from '../../../../_components/StreaksButton/StreaksButton';
import { useStatusPanelHidden } from '../../hooks/useStatusPanelHidden';

import { ReactComponent } from '../../../../../svg/img/check.svg';

export function StreakSaved() {
  const { t } = useTranslation();
  const { hide } = useStatusPanelHidden('streakSaved');

  return (
    <StatusPanel
      title={t('StreaksPage.StatusPanelTitle.StreakSaved')}
      Icon={({ className }) => (
        <ReactComponent
          className={className}
          style={{ color: '#30D158' }}
        />
      )}
      button={
        <StreaksButton onClick={hide}>
          <Trans i18nKey="StreaksPage.StatusPanelButton.StreakSaved"/>
        </StreaksButton>
      }
    >
      <Trans i18nKey="StreaksPage.StatusPanelText.StreakSaved"/>
    </StatusPanel>
  );
}
