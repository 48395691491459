import { useMemo } from 'react';

import { useUTCDaysDiff } from '../../../hooks/dates/useUTCDaysDiff';
import { useStatusPanelHidden } from './useStatusPanelHidden';
import { useUserPocket } from '../../../hooks/pocket/queries/useUserPocket';
import { useStreaksUserState } from '../../../hooks/streaks/useStreaksUserState';
import {
  type DailyTaskMainApp,
  useStreaksDailyTaskMainApp,
} from '../../../hooks/streaks/useStreaksDailyTaskMainApp';

type Result =
  | [status: 'loading']
  | [status: 'task-completed']
  | [status: 'streak-almost-lost', livesToRestore: number, canRestore: boolean]
  | [status: 'streak-saved']
  | [status: 'complete-task', dailyApp: DailyTaskMainApp]
  | undefined;

export function useDailyTask(): Result {
  const {
    data: userState,
    isLoading: isUserStateLoading,
  } = useStreaksUserState();
  const {
    data: userPocket,
    isLoading: isUserPocketLoading,
  } = useUserPocket();
  const app = useStreaksDailyTaskMainApp();
  const daysDiff = useUTCDaysDiff();

  // const {
  //   isLoading: isSavedPanelHiddenLoading,
  //   isHidden: isSavedPanelHidden,
  // } = useStatusPanelHidden('streakSaved');
  const {
    isLoading: isTaskCompletedPanelHiddenLoading,
    isHidden: isTaskCompletedPanelHidden,
  } = useStatusPanelHidden('taskCompleted');

  return useMemo<Result>(() => {
    if (app === 'loading' || isUserStateLoading) {
      return ['loading'];
    }

    const appState: Result = app
      ? ['complete-task', app]
      : undefined;

    // No user data fetched. The only thing we can do here is just to display the daily task.
    if (!userState) {
      return appState;
    }

    // User didn't complete the daily task, display it.
    const { latestCompletedTaskDate } = userState;
    if (!latestCompletedTaskDate) {
      return appState;
    }

    // Now, compute how many days elapsed since the last task completion date.
    const daysElapsed = -daysDiff(latestCompletedTaskDate);

    // No days elapsed, it means the task was completed today.
    if (!daysElapsed) {
      return isTaskCompletedPanelHiddenLoading
        ? ['loading']
        : isTaskCompletedPanelHidden
          // When the task was completed and the panel is hidden, we are not display it.
          ? undefined
          : ['task-completed'];
    }

    // 1 day elapsed, time to complete the daily task.
    if (daysElapsed === 1) {
      return appState;
    }

    // Before showing the user the information about his streak was lost or almost lost, we
    // should retrieve his pocket information.
    if (isUserPocketLoading) {
      return ['loading'];
    }

    // We were unable the fetch the user pocket. It is probably better not to display anything
    // because we don't really know if the current lives count is enough to restore the streak.
    if (!userPocket) {
      // TODO: Should probably display some information panel with error. Otherwise, the user
      //  may have some confusion.
      return;
    }

    // The streak was almost lost.
    const daysToRestore = daysElapsed - 1;
    const { lives } = userPocket;
    const canRestore = lives >= daysToRestore;
    return [
      'streak-almost-lost',
      canRestore
        // If the user can restore the streak, we are going to display how much it will cost him.
        ? daysToRestore
        // Otherwise, we are going to show him, how many lives he has to buy to restore the streak.
        : daysToRestore - lives,
      canRestore,
    ];
  }, [
    app,
    userState,
    isUserStateLoading,
    userPocket,
    isUserPocketLoading,
    isTaskCompletedPanelHiddenLoading,
    isTaskCompletedPanelHidden,
    daysDiff,
  ]);
}