// refs: https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript

import isMobile from './isMobile'

export function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

let scrollBarWidth: number = 0
export const checkScrollbarWidth = () => {
    if (isMobile(navigator).any) {
        return
    }

    document.documentElement.style.setProperty('--scrollbar-width', `0px`)

    if (!scrollBarWidth) {
        scrollBarWidth = getScrollbarWidth()
    }

    if (document.body.clientWidth === window?.innerWidth) {
        document.documentElement.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`)
    }
}

const gradients: [string, string][] = [['#53EDD6', '#28C9B7'], ['#FFCD6A', '#FFA85C'], ['#E0A2F3', '#D669ED'], ['#A7ADB9', '#878B96'], ['#FF885E', '#FF516A']];

export function getStringGradient(text: string): [string, string] {
    const charCodes = text
        .split('')
        .map(char => char.charCodeAt(0))
        .join('');
    return gradients[parseInt(charCodes, 10) % gradients.length];
}

