import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr';

import { useApiClient } from '../../../providers/ApiClientProvider/context';

export interface PocketCollection {
  collectionId: string;
  name: string;
  description: string;
  price: number;
  // fixme
  type?: string;
}

/**
 * Requests user pocket collections.
 */
export function usePocketCollections(
  options?: SWRConfiguration<PocketCollection[]>,
): SWRResponse<PocketCollection[]> {
  const client = useApiClient();
  return useSWR<PocketCollection[]>('POCKET_COLLECTIONS', client.getPocketCollections.bind(client), {
    ...options,
    onError(err, key, config) {
      console.error('Error fetching pocket collections', err);
      options && options.onError && options.onError(err, key, config);
    },
  });
}