import { useDataContext } from '../../../dataSource/DataContext/DataContext';
import { useScrollRestoration } from '../../../hooks/useScrollRestoration';
import { TWAFooter } from '../../../uilib/Footer/TWAFooter';
import { TWAHeader } from '../../../uilib/Header/TWAHeader';
import { TWATabs } from '../../../uilib/Tabs/TWATabs';
import { StreaksBanner } from '../../_components/StreaksBanner/StreaksBanner';
import { Block } from '../../_blocks/Block/Block';
import { WithColoredUI } from '../../_components/WithColoredUI';

import styles from '../page.module.scss';

export default function IndexPage() {
  const { mainPageBlockConfig } = useDataContext();
  const { ref } = useScrollRestoration('indexPage', {
    debounceTime: 200,
  });

  return (
    <WithColoredUI>
      <main ref={ref} className={styles.main}>
        <TWAHeader/>
        <TWATabs/>
        <div style={{ marginTop: window.isRecentlyLaunchedExists ? 14 : 0 }}>
          <StreaksBanner/>
        </div>
        {(mainPageBlockConfig || []).map((block, index) => {
          return (
            <Block
              key={block.id}
              blockData={block}
              // todo: replace index + 1 with index when StreaksBanner becomes a usual Block.
              index={index + 1}
              config={mainPageBlockConfig}
            />
          );
        })}
        <div className={styles.footerWrapper}>
          <TWAFooter/>
        </div>
      </main>
    </WithColoredUI>
  );
}
