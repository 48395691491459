import useSWRMutation from 'swr/mutation';

import { useApiClient } from '../../providers/ApiClientProvider/context';
import { useErrorLog } from '../useErrorLog';
import { useStreaksUserState } from './useStreaksUserState';

/**
 * Returns mutation to complete streaks daily task.
 */
export function useCompleteStreaksDailyTask() {
  const client = useApiClient();
  const userState = useStreaksUserState();
  const response = useSWRMutation(
    'COMPLETE_STREAKS_DAILY_TASK',
    (_, { arg }: { arg: number }) => client.completeStreaksDailyTask(arg),
    {
      onSuccess() {
        // The task was completed. We should revalidate the user state.
        void userState.mutate();
      }
    }
  );

  useErrorLog('Error completing streaks daily task', response.error);

  return response;
}