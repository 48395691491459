import cn from 'classnames';
import { HTMLAttributes } from 'react';

import s from './StreaksShimmer.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: string | number;
  height?: string | number;
  rounded?: boolean;
}

export function StreaksShimmer({
  className,
  rounded,
  width,
  height,
  style,
  ...rest
}: Props) {
  return (
    <div
      className={cn(s.root, rounded && s.rounded, className)}
      style={{ width, height, ...style }}
      {...rest}
    />
  );
}
