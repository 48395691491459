import { useTranslation } from 'react-i18next';

import { useTonConnect } from '../../../providers/TonConnectProvider/context';
import { usePendingOrder } from './usePendingOrder';
import { useActiveOrder } from '../../../hooks/pocket/queries/useActiveOrder';
import { useUserPocket } from '../../../hooks/pocket/queries/useUserPocket';
import { usePurchasePocketItems } from '../../../hooks/pocket/mutations/usePurchasePocketItems';
import { useMainButton } from './useMainButton';
import { useSelectedItems } from './useSelectedItems';

/**
 * Hook providing utilities for the page.
 */
export function useToolkit() {
  // Use controls to work with selected items.
  const {
    totalPrice,
    getCountSelected,
    changeCountSelected,
    getSelectedItems,
    resetSelected,
  } = useSelectedItems();

  const { HapticFeedback, showAlert } = window.Telegram.WebApp;
  const { t } = useTranslation();
  const { wallet } = useTonConnect();

  // True if the active order is currently being polled.
  const [hasPendingOrder, { set: setHasPendingOrder }] = usePendingOrder();

  useActiveOrder({
    shouldCall: !!hasPendingOrder.data,
    refreshInterval: 2000,
    onSuccess(data) {
      if (data) {
        const { status } = data;
        if (status === 'COMPLETED') {
          void userPocket.mutate();
          void setHasPendingOrder(false);
          resetSelected();

          // Making notification asynchronous as long as calling it right away will prevent
          // the Main Button from hiding. resetSelected will drop selected items, but then
          // the alert will be shown preventing the code from getting to the Main Button state
          // update. As the result the Main Button will be updated only when the alert was hidden.
          setTimeout(() => {
            showAlert(t('PocketPage.PurchaseCompleted'));
            HapticFeedback.notificationOccurred('success');
          }, 100);
          return;
        }

        // The active order is currently in progress, we should keep tracking its changes.
        if (status === 'PENDING') {
          return setHasPendingOrder(true);
        }
      }

      // In all other cases, we just stop polling.
      void setHasPendingOrder(false);
    },
  });

  const userPocket = useUserPocket();
  const purchasePocketItems = usePurchasePocketItems({
    onSuccess() {
      // When items were successfully purchased, store the transaction ID.
      setHasPendingOrder(true);
    },
  });

  const isLoading = purchasePocketItems.isMutating || !!hasPendingOrder.data;

  useMainButton({
    isLoading,
    totalPrice,
    purchase() {
      wallet && purchasePocketItems.trigger({
        wallet: wallet.address,
        items: getSelectedItems(),
      });
    },
  });

  return {
    totalPrice,
    getCountSelected,
    changeCountSelected,
    isLoading,
  };
}