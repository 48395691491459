import { useEffect, useState } from "react";
import { getReferrals } from "../../utils/api/requests";
import { Referral } from "../../../types/entities";

export interface useReferralsParams {
    programId?: number;
    showAll?: boolean;
}

export function useReferrals({programId, showAll = false}: useReferralsParams) {
    const [isLoading, setIsLoading] = useState(true);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [error, setError] = useState<unknown | null>(null);
    const [referrals, setReferrals] = useState<Referral[]>([]);
    const [referralsTotal, setReferralsTotal] = useState<number>(0);

    useEffect(() => {
        const load = async () => {
            if (!programId) {
                return;
            }

            setIsLoading(true);
            setError(null);
            const controller = new AbortController();
            setAbortController(controller);

            try {
                const data = await getReferrals(
                    {initData: window.Telegram.WebApp.initData, programId, showAll},
                    {signal: controller.signal}
                );
                if (Array.isArray(data.items)) {
                    data.items.forEach((referral) => {
                        referral.joinedAt = new Date(referral.joinedAt);
                    });
                    setReferrals(data.items);
                    setReferralsTotal(data.total);
                }
            } catch (error) {
                if (error instanceof DOMException && error.name === 'AbortError') {
                    // aborted, do nothing
                } else {
                    setError(error);
                    console.error('Failed to fetch referrals.', error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        load();

        return () => {
            if (abortController) {
                abortController.abort();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programId]);

    return {
        isLoading,
        error,
        referrals,
        referralsTotal
    }
}
