import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

import { openTelegramLink } from '../../../../utils/common';
import { useCompleteStreaksDailyTask } from '../../../hooks/streaks/useCompleteStreaksDailyTask';
import { useStreaksDailyTasks } from '../../../hooks/streaks/useStreaksDailyTasks';

export function useOnDailyTaskAppOpened() {
  const { data: dailyTasks } = useStreaksDailyTasks();
  const completeDailyTask = useCompleteStreaksDailyTask();

  return useCallback(() => {
    if (!dailyTasks) {
      return;
    }

    const { main } = dailyTasks;
    if (!main) {
      return;
    }

    const { app } = main;
    if (!app) {
      return;
    }

    // TODO: We should probably add more analytics here.
    mixpanel.track('App Launch', {
      'app_name': app.title,
      'app_id': app.id,
      'section': 'Streaks',
    });

    mixpanel.track('task_started', {
      'app_name': app.title,
      'task_type': 'daily',
    });

    completeDailyTask.trigger(main.id);
    const url = app.webappUrl || app.url;
    url && openTelegramLink(url);
  }, [dailyTasks, completeDailyTask]);
}
