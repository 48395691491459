import type { PropsWithChildren } from 'react';

import { ReferralProgramsProvider } from '../../dataSource/ReferralProgramsContext';
import { ConfigProvider } from '../../dataSource/ConfigContext';
import { TonConnectProvider } from '../providers/TonConnectProvider/TonConnectProvider';
import { DataProvider } from '../../dataSource/DataContext/DataContext';
import { ServerTimeProvider } from '../providers/ServerTimeProvider/ServerTimeProvider';
import type { AppConfig, TWACategory } from '../../../types/entities';
import type { BlockData } from '../../../types/Blocks';

export interface ProvidersProps {
  appConfig: AppConfig | null | undefined;
  appsRanking: number[] | undefined;
  categories: TWACategory[] | undefined;
  mainPageBlockConfig: BlockData[] | undefined;
  navigationConfig: TWACategory[] | undefined;
  tonConnectManifestUrl: string;
  tonConnectReturnUrl: `${string}://${string}`;
}

/**
 * This component that holds all global providers defined in the application.
 */
export function Providers({
  children,
  appConfig,
  categories,
  appsRanking,
  navigationConfig,
  mainPageBlockConfig,
  tonConnectReturnUrl,
  tonConnectManifestUrl,
}: PropsWithChildren<ProvidersProps>) {
  return (
    <ServerTimeProvider>
      <ReferralProgramsProvider>
        <ConfigProvider config={appConfig}>
          <DataProvider
            navigationConfig={navigationConfig}
            mainPageBlockConfig={mainPageBlockConfig}
            categories={categories}
            appsRanking={appsRanking}
          >
            <TonConnectProvider
              manifestUrl={tonConnectManifestUrl}
              returnUrl={tonConnectReturnUrl}
            >
              {children}
            </TonConnectProvider>
          </DataProvider>
        </ConfigProvider>
      </ReferralProgramsProvider>
    </ServerTimeProvider>
  );
}