import { Trans } from 'react-i18next';
import { ReactNode } from 'react';

import { StreaksPanel } from '../../../../../_components/StreaksPanel/StreaksPanel';
import {
  Body,
  Caption,
  SubHeadline,
} from '../../../../../../uilib/Typography/Typography';
import { StreaksShimmer } from '../../../../../_components/StreaksShimmer/StreaksShimmer';
import { createSrcSet } from '../../../../../../utils/createSrcSet';

import { ReactComponent as ParticipantsIcon } from '../../../../../../svg/img/people.svg';
import { ReactComponent as RewardIcon } from '../../../../../../svg/img/reward.svg';
import s from './League.module.scss';

export interface LeagueData {
  img: {
    size: number;
    src: string;
    srcSet: string;
  },
  background: string;
  title: string;
  streakDays: number;
  participants: number;
  additionalRewards?: boolean;
}

/**
 * Formats the number inserting spaces between every three digits from the number's lower part.
 * @param number - number to format.
 */
function formatNumber(number: number): string {
  return new Intl.NumberFormat('fr-FR').format(number);
}

/**
 * Formats participants count.
 * @param count - number to format.
 */
function formatParticipants(count: number): string {
  let result: string;

  if (count < 1000) {
    result = formatNumber(count);
  } else {
    // Take this part: >123<456
    const thousands = count / 1000 | 0;

    if (count < 1_000_000) {
      // Take this part: 123>4<56
      const other = count % 1000 / 100 | 0;
      result = formatNumber(thousands) + (other > 0 ? `.${other}` : '');
    } else {
      result = formatNumber(thousands);
    }
    result += 'K';
  }

  return result;
}

export function League({ data, index }: {
  index: number;
  data: 'loading' | LeagueData;
}) {
  let headerNode: ReactNode;
  let imageNode: ReactNode;
  let participantsNode: ReactNode;
  let additionalRewardsNode: ReactNode;

  if (data === 'loading') {
    headerNode = (
      <>
        <StreaksShimmer width="90%" height={20}/>
        <StreaksShimmer className={s.streakShimmer}/>
      </>
    );
    imageNode = (
      <StreaksShimmer
        className={s.imgShimmer}
        width={100}
        height={130}
      />
    );

    participantsNode = (
      <StreaksShimmer
        className={s.participantsShimmer}
        width={70}
        height={18}
      />
    );
    additionalRewardsNode = <StreaksShimmer width={90} height={18}/>;
  } else {
    const {
      img,
      title,
      streakDays,
      participants,
      additionalRewards,
    } = data;
    headerNode = (
      <>
        <Body weight="regular">{data.title}</Body>
        <SubHeadline className={s.streak} level={2} weight="regular">
          <Trans i18nKey="StreaksPage.LeagueStreakTitle" count={streakDays}/>
        </SubHeadline>
      </>
    );
    imageNode = (
      <img
        className={s.img}
        style={{ animationDelay: `${index * 500}ms` }}
        alt={`${title} league`}
        src={img.src}
        srcSet={Array.isArray(img.srcSet)
          ? createSrcSet(img.srcSet)
          : img.srcSet}
        width={img.size}
        height={img.size}
        onContextMenu={e => {
          e.preventDefault();
        }}
      />
    );

    participantsNode = (
      <SubHeadline className={s.participants} level={2} weight="medium">
        {participants ? (
          <>
            {formatParticipants(participants)}
            <ParticipantsIcon/>
          </>
        ) : <Trans i18nKey="StreaksPage.BeFirstInLeague"/>}
      </SubHeadline>
    );

    if (additionalRewards) {
      additionalRewardsNode = (
        <Caption className={s.rewards} level={2} weight="semi-bold">
          <RewardIcon/>
          <Trans i18nKey="StreaksPage.LeagueExtraRewards"/>
        </Caption>
      );
    }
  }

  return (
    <StreaksPanel
      className={s.root}
      style={{
        backgroundImage: typeof data === 'object' ? data.background : undefined,
        animationDelay: `${index * 400}ms`,
      }}
    >
      <div className={s.content}>
        <div>
          <div className={s.header}>
            {headerNode}
          </div>
          {imageNode}
        </div>
        <div className={s.footer}>
          {participantsNode}
          {additionalRewardsNode}
        </div>
      </div>
    </StreaksPanel>
  );
}
