import { type PropsWithChildren, useMemo } from 'react';

import { AppStateContext, type AppStateContextType } from './context';

export function AppStateProvider({
  isLoaderVisible,
  isInitialDataLoaded,
  ...rest
}: PropsWithChildren<AppStateContextType>) {
  const context = useMemo(() => ({
    isLoaderVisible,
    isInitialDataLoaded,
  }), [isLoaderVisible, isInitialDataLoaded]);

  return <AppStateContext.Provider value={context} {...rest}/>;
}