import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';

import { useApiClient } from '../../../providers/ApiClientProvider/context';

/**
 * @returns Mutation to purchase pocket items.
 * @param options - additional options.
 */
export function usePurchasePocketItems(
  options?: SWRMutationConfiguration<void, unknown, string, {
    wallet: string;
    items: {
      collectionId: string,
      count: number
    }[];
  }>,
) {
  const client = useApiClient();
  return useSWRMutation(
    'PURCHASE_POCKET_ITEMS',
    (_, { arg }) => {
      return client.purchasePocketItems(arg.wallet, arg.items);
    },
    {
      ...options,
      onError(err, key, config) {
        console.error('Error purchasing pocket items', err);
        options && options.onError && options.onError(err, key, config);
      },
    },
  );
}