import { StreakArtifacts } from './StreakArtifacts/StreakArtifacts';
// import { Web3Artifacts } from './Web3Artifacts/Web3Artifacts';
import { WithColoredUI } from '../../_components/WithColoredUI';
import { Artifacts } from './Artifacts/Artifacts';

import styles from './PocketPage.module.scss';

export function PocketPage() {
  return (
    <WithColoredUI color="#1C1C1E">
      <div className={styles.root}>
        <StreakArtifacts/>
        {/*<Web3Artifacts/>*/}
        <Artifacts/>
      </div>
    </WithColoredUI>
  );
}
