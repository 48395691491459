import { useMemo } from 'react';

import { findLeagueById, initialLeague } from '../../leagues/leagues';
import { useIsUTCToday } from '../../hooks/dates/useIsUTCToday';
import { useStreaksUserState } from '../../hooks/streaks/useStreaksUserState';

export function useStreaks() {
  const { isLoading, data } = useStreaksUserState();
  const isToday = useIsUTCToday();

  return useMemo(() => {
    if (isLoading) {
      return 'loading';
    }
    if (!data) {
      return;
    }
    const {
      streakDays,
      currentLeague,
      nextLeague,
      latestCompletedTaskDate,
    } = data;

    let currentLeagueData: {
      title: string;
      streakDays: number;
    } = {
      title: initialLeague.title,
      streakDays: 0,
    };

    if (currentLeague) {
      if (!findLeagueById(currentLeague.id)) {
        console.error(`Unable to find current league with ID ${currentLeague.id}`);
        return;
      }
      currentLeagueData = {
        title: currentLeague.name,
        streakDays: currentLeague.streakDays,
      };
    }

    let nextLeagueData: {
      title: string;
      streakDays: number;
    } | undefined;

    if (nextLeague) {
      if (!findLeagueById(nextLeague.id)) {
        console.error(`Unable to find next league with ID ${nextLeague.id}`);
        return;
      }
      nextLeagueData = {
        title: nextLeague.name,
        streakDays: nextLeague.streakDays,
      };
    }

    return {
      streak: streakDays,
      currentLeague: currentLeagueData,
      nextLeague: nextLeagueData,
      isDailyCompleted: latestCompletedTaskDate
        ? isToday(latestCompletedTaskDate)
        : false,
    };
  }, [isLoading, data, isToday]);
}