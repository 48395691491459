import useSWR from 'swr';

import { getItemFromStorage, setItemToStorage } from '../../../../hooks/hooks';

const CLOUD_STORAGE_KEY = 'onboardingCompleted';
const CLOUD_STORAGE_VALUE = '1';

export function useOnboardingCompleted() {
  const {
    // hidden before we ensure it's not completed
    data = true,
    isLoading,
    mutate,
  } = useSWR('isOnboardingCompleted', () => {
    return getItemFromStorage(CLOUD_STORAGE_KEY, 5000)
      .then((value) => {
        return value === CLOUD_STORAGE_VALUE;
    })
      .catch(() => {
        // if error, consider it's completed
        return true
    });
  });

  return {
    isHidden: data,
    isLoading,
    hide() {
      mutate(true, { revalidate: false }).catch(console.error);
      setItemToStorage(CLOUD_STORAGE_KEY, CLOUD_STORAGE_VALUE).catch(console.error);
    },
  };
}
