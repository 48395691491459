import useSWR from 'swr';

import { useApiClient } from '../../../../providers/ApiClientProvider/context';

export function useLeaderboardRank() {
  const client = useApiClient();
  return useSWR('LEADERBOARD_RANK', client.getStreaksLeaderboardRank.bind(client), {
    onError(err) {
      console.error('Error retrieving streaks leaderboard rank', err);
    },
  });
}