import { ApiError } from './ApiError';
import { FetchError } from './FetchError';
import { InvalidResponseTypeError } from './InvalidResponseTypeError';
import { InvalidResponseError } from './InvalidResponseError';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';

type ResponseType =
  | typeof RESPONSE_TYPE_JSON
  | typeof RESPONSE_TYPE_TEXT
  | typeof RESPONSE_TYPE_BLOB
  | typeof RESPONSE_TYPE_ARRAY_BUFFER;

interface RequestOptions<Body = unknown> {
  headers?: Record<string, string>;
  body?: Body;
  queryParams?: Record<string, string | number | boolean>;
  credentials?: RequestCredentials;
  signal?: AbortSignal;
  responseType?: ResponseType;
  timeout?: number;
}

interface ApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
  headers: Headers;
}

const RESPONSE_TYPE_JSON = 'json';
const RESPONSE_TYPE_TEXT = 'text';
const RESPONSE_TYPE_BLOB = 'blob';
const RESPONSE_TYPE_ARRAY_BUFFER = 'arrayBuffer';

const DEFAULT_RESPONSE_TYPE = RESPONSE_TYPE_JSON;
const DEFAULT_CREDENTIALS = 'same-origin';

interface Options {
  /**
   * Default headers.
   */
  headers?: Record<string, string>;
  /**
   * Default request options.
   */
  requestOptions?: RequestOptions;
}

type TypedRequestOptions = Omit<RequestOptions, 'body'>;

type ValidateResponseFn<T = unknown> = (data: unknown) => T;

interface MethodWithBody {
  /**
   * Performs a request with unknown response.
   * @param url - URL to send request to.
   * @param body - request payload.
   * @param options - additional request options.
   */<Body = unknown>(
    url: string,
    body: Body,
    options?: TypedRequestOptions,
  ): Promise<ApiResponse<unknown>>;

  /**
   * Performs a request and validates the response.
   * @param url - URL to send request to.
   * @param body - request payload.
   * @param validate - function to validate the response.
   * @param options - additional request options.
   */<Res, Body = unknown>(
    url: string,
    body: Body,
    validate: ValidateResponseFn<Res>,
    options?: TypedRequestOptions,
  ): Promise<ApiResponse<Res>>;
}

interface MethodWithoutBody {
  /**
   * Performs a request with unknown response.
   * @param url - URL to send request to.
   * @param options - additional request options.
   */
  (url: string, options?: TypedRequestOptions): Promise<ApiResponse<unknown>>;

  /**
   * Performs a request and validates the response.
   * @param url - URL to send request to.
   * @param validate - function to validate the response.
   * @param options - additional request options.
   */<Res>(
    url: string,
    validate: ValidateResponseFn<Res>,
    options?: TypedRequestOptions,
  ): Promise<ApiResponse<Res>>;
}

export class HttpClient {
  private readonly baseUrl: string;
  private readonly defaultHeaders: Record<string, string>;
  private readonly defaultRequestOptions: Omit<RequestOptions, 'method'>;

  /**
   * Initializes the HttpClient with optional base URL and default options.
   * @param baseUrl - the base URL for all requests.
   * @param options - additional client options.
   */
  constructor(baseUrl: string, options?: Options) {
    const { headers, requestOptions } = options || {};

    this.baseUrl = baseUrl;
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...headers,
    };
    this.defaultRequestOptions = {
      responseType: DEFAULT_RESPONSE_TYPE,
      credentials: DEFAULT_CREDENTIALS,
      ...requestOptions,
    };
  }

  private createMethodWithBody(method: HttpMethod): MethodWithBody {
    return ((url, body, arg1, arg2) => {
      let validator: ValidateResponseFn | undefined;
      let options: RequestOptions = {};

      if (arg1) {
        if (typeof arg1 === 'function') {
          validator = arg1;
        } else {
          options = arg1;
        }
      }

      if (arg2) {
        validator = arg1 as ValidateResponseFn<unknown>;
        options = arg2;
      }

      return this.request(method, url, validator || (d => d), {
        ...options,
        body,
      });
    }) as MethodWithBody;
  }

  private createMethodWithoutBody(method: HttpMethod): MethodWithoutBody {
    const withBody = this.createMethodWithBody(method);

    return ((url, arg1, arg2) => {
      return withBody(url, undefined, arg1, arg2);
    }) as MethodWithoutBody;
  }

  /**
   * Core request method handling all HTTP requests.
   * @param method - HTTP method name.
   * @param url - The endpoint URL (relative to baseUrl if set).
   * @param validate - function to validate the response.
   * @param options - The request options including method, headers, body, etc.
   * @returns A promise that resolves to the ApiResponse with typed data.
   * @throws ApiError if the response status is not in the 200-299 range.
   */
  async request<Res, Body = any>(
    method: HttpMethod,
    url: string,
    validate: ValidateResponseFn<Res>,
    options?: RequestOptions<Body>,
  ): Promise<ApiResponse<Res>> {
    const mergedOptions = { ...this.defaultRequestOptions, ...options };

    const fetchOptions = {
      method,
      headers: {
        ...this.defaultHeaders,
        ...mergedOptions.headers,
      },
      credentials: mergedOptions.credentials || DEFAULT_CREDENTIALS,
      signal: mergedOptions.signal,
    };

    const { body } = mergedOptions;
    let requestBody: BodyInit | undefined;

    if (body !== undefined && method !== 'GET' && method !== 'HEAD') {
      if (body instanceof FormData || body instanceof Blob) {
        requestBody = body;

        // Let the browser set the appropriate Content-Type
        if (body instanceof FormData) {
          delete fetchOptions.headers['Content-Type'];
        }
      } else if (typeof body === 'object') {
        requestBody = JSON.stringify(body);

        // Ensure Content-Type is set to application/json
        if (!('Content-Type' in fetchOptions.headers)) {
          fetchOptions.headers['Content-Type'] = 'application/json';
        }
      } else {
        requestBody = String(body);
      }
    }

    const completeUrl = new URL(
      // Remove all slashes in the begging to prevent escaping the base URL.
      url.replace(/^\/+/, ''),
      this.baseUrl,
    );

    const { queryParams } = mergedOptions;
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        completeUrl.searchParams.append(key, String(value));
      });
    }

    // Step 1: perform the request.
    const { timeout, signal } = mergedOptions;
    const controller = new AbortController();
    const signalListener = (abortReason: unknown) => {
      controller.abort(abortReason);
    };
    if (signal) {
      signal.addEventListener('abort', signalListener);
    }

    let timeoutId: number | undefined;
    if (timeout) {
      timeoutId = setTimeout(() => {
        controller.abort(new Error(`Timed out: ${timeout}ms`));
      }, timeout) as unknown as number;
    }

    let response: Response;
    try {
      response = await fetch(completeUrl.toString(), {
        ...fetchOptions,
        signal: controller.signal,
        body: requestBody,
      });
    } catch (e) {
      throw new FetchError(e);
    } finally {
      signal && signal.removeEventListener('abort', signalListener);
      clearTimeout(timeoutId);
    }

    // Step 2: extract response data.
    const responseType = mergedOptions.responseType || DEFAULT_RESPONSE_TYPE;
    let data: unknown;
    try {
      data = await (
        responseType === RESPONSE_TYPE_JSON
          ? response.json()
          : responseType === RESPONSE_TYPE_ARRAY_BUFFER
            ? response.arrayBuffer()
            : responseType === RESPONSE_TYPE_BLOB
              ? response.blob()
              : response.text()
      );
    } catch (e) {
      const dataString = await response.text().catch(() => '[Non-presentable]');

      if (!response.ok) {
        throw new ApiError(response.status, response.statusText, dataString);
      }
      throw new InvalidResponseTypeError(responseType, dataString, e);
    }

    if (!response.ok) {
      throw new ApiError(response.status, response.statusText, data);
    }

    // Step 3: validate the response.
    try {
      return {
        data: validate(data),
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
      };
    } catch (e) {
      throw new InvalidResponseError(data, e);
    }
  }

  /**
   * Performs a GET request.
   */
  get = this.createMethodWithoutBody('GET');

  /**
   * Performs a POST request.
   */
  post = this.createMethodWithBody('POST');

  /**
   * Performs a PUT request.
   */
  put = this.createMethodWithBody('PUT');
}
