import type { ComponentType, PropsWithChildren, ReactNode } from 'react';

import { StreaksPanel } from '../../../../../_components/StreaksPanel/StreaksPanel';
import { Callout, Heading } from '../../../../../../uilib/Typography/Typography';

import s from './StatusPanel.module.scss';

export function StatusPanel({ title, children, button, Icon }: PropsWithChildren<{
  title: string;
  Icon: ComponentType<{ className?: string }>;
  button?: ReactNode;
}>) {
  const titleWords = title.split(' ');

  return (
    <StreaksPanel className={s.root}>
      <Heading className={s.title} weight="bold" type="h4">
        {titleWords.slice(0, titleWords.length - 1).join(' ')}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          {titleWords[titleWords.length - 1]}
          <Icon className={s.icon}/>
        </span>
      </Heading>
      <Callout className={s.content} weight="regular">
        {children}
      </Callout>
      <div className={s.button}>
        {button}
      </div>
    </StreaksPanel>
  );
}