import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { TelegramWebApps } from '../../../../types/common';
import { useOpenAppCommand } from '../../../hooks/app/useOpenAppCommand';
import { SearchIcon } from '../../../svg/SearchIcon';
import { isNewApp } from '../../../utils/dates';
import { AndroidInput } from '../../AndroidInput/AndroidInput';
import { ApplicationCard } from '../../ApplicationCard/ApplicationCard';
import { TWAApplicationCard } from '../../ApplicationCard/TWAApplicationCard';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { Body } from '../../Typography/Typography';
import { AndroidControls } from '../components/Controls/AndroidControls';
import { Controls } from '../components/Controls/Controls';
import { SearchResults } from '../components/SearchResults/SearchResults';
import { useSearch } from '../hooks/useSearch';
import { useTouchOutside } from '../hooks/useTouchOutside';

import styles from '../style.module.scss';

interface ITWASearchProps {
    platform?: TelegramWebApps.Platform;
}

export const TWASearch: React.FC<ITWASearchProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const search = useSearch(inputRef);
    const { createOpenAppCommand } = useOpenAppCommand();
    const { t } = useTranslation();

    useTouchOutside(inputRef, search.blur);

    useEffect(() => {
        search.reset();

        if (search.value.length > 0) {
            search.requestData(search.value);
        }
    }, [search.value, search.requestData]);

    return (
        <React.Fragment>
            <div className={styles.root}>
                {
                    (props.platform === 'android' || props.platform === 'tdesktop')
                        ? (
                            <AndroidInput
                                ref={inputRef}
                                placeholder={t('search')}
                                onChange={search.handleChange}
                                onFocus={(e) => {
                                    search.changeSearchState(true);
                                }}
                                value={search.value}
                            >
                                <div className={classNames(styles.actionHolder, styles.android)}>
                                    <AndroidControls
                                        value={search.value}
                                        isLoading={search.isLoading}
                                        onActionClick={search.resetInputValue}
                                    />
                                </div>
                            </AndroidInput>
                        ) : (
                            <>
                                <Input
                                    ref={inputRef}
                                    icon={<SearchIcon />}
                                    placeholder={t('search')}
                                    onChange={search.handleChange}
                                    onFocus={(e) => {
                                        search.changeSearchState(true);
                                    }}
                                    value={search.value}
                                >
                                    {
                                        search.isActive && (
                                            <div className={styles.actionHolder}>
                                                <Controls
                                                    value={search.value}
                                                    isLoading={search.isLoading}
                                                    onActionClick={search.resetInputValue}
                                                />
                                            </div>
                                        )
                                    }
                                </Input>
                                <Button
                                    type={'tertiary'}
                                    onClick={search.handleCancel}
                                    className={classNames(styles.cancelButton, search.isActive && styles.visible)}
                                    tabIndex={-1}
                                >
                                    <Body
                                        weight={'regular'}
                                        className={styles.label}
                                    >
                                        {t("cancel")}
                                    </Body>
                                </Button>
                                </>
                        )
                }
            </div>
            {
                search.isStarted && (
                    createPortal(
                        <SearchResults
                            data={search.data || []}
                            visible={search.isStarted}
                            searchIsCompleted={search.isCompleted}
                            className={classNames(styles.searchResults, (props.platform === 'android' || props.platform === 'tdesktop') && styles.android)}
                            searchValue={search.value}
                            renderCard={(item) => {
                                const { attributes } = item;

                                return (
                                    <TWAApplicationCard
                                        url={attributes.url}
                                        onButtonClick={() => {
                                            createOpenAppCommand(item, "Search");
                                        }}
                                        key={item.attributes.title}
                                        title={attributes.title}
                                        isNew={isNewApp(attributes.createdAt)}
                                        path={attributes.path}
                                        description={attributes.description}
                                        editorsChoice={Boolean(attributes.editors_choice)}
                                        img={{
                                            src: attributes?.icon?.data?.attributes.url ?? "",
                                            name: attributes?.icon?.data?.attributes.name ?? "",
                                        }}
                                    />
                                )
                            }}
                            renderShimmer={(item, index) => (
                                <ApplicationCard key={index} {...(item || [])} disableOnClick noFocus/>
                            )}
                        />,
                        document.getElementById("searchResultsPortal")!,
                    )
                )
            }
        </React.Fragment>
    )
}
