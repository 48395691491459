import { Trans } from 'react-i18next';

import { Section } from '../Section/Section';
import { League } from './League/League';
import { Heading } from '../../../../../uilib/Typography/Typography';
import { StreaksAnimatePresence } from '../../../../_components/StreaksAnimatePresence';
import { useLeagues } from '../../hooks/useLeagues';
import { useIsSetOnce } from '../../../../hooks/useIsSetOnce';

import s from './Leagues.module.scss';

export function Leagues() {
  const data = useLeagues();
  const vanishedOnce = useIsSetOnce(!data);

  return (
    <StreaksAnimatePresence>
      {(data && (data !== 'loading' || !vanishedOnce)) && (
        <Section>
          <Heading weight="bold" className={s.title}>
            <Trans i18nKey="StreaksPage.LeaguesTitle"/>
          </Heading>
          <div className={s.leagues}>
            {data === 'loading'
              ? new Array(4).fill(0).map((_, idx) => (
                <League index={idx} key={idx} data="loading"/>
              ))
              : data.map((l, idx) => (
                <League index={idx} key={idx} data={l}/>
              ))}
          </div>
        </Section>
      )}
    </StreaksAnimatePresence>
  );
}
