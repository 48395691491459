import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StatusPanel } from '../StatusPanel/StatusPanel';
import { StreaksButton } from '../../../../../_components/StreaksButton/StreaksButton';
import { useToolkit } from './useToolkit';

import { ReactComponent as WarningIcon } from '../../../../../../svg/img/warning.svg';

import s from './StreakAlmostLost.module.scss';

export function StreakAlmostLost({ livesRequired, canRestore }: {
  livesRequired: number;
  canRestore: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, restoreStreak } = useToolkit();

  return (
    <StatusPanel
      title={t('StreaksPage.StatusPanelTitle.StreakAlmostLost')}
      Icon={({ className }) => (
        <WarningIcon
          className={className}
          style={{ color: '#FF9F0A' }}
        />
      )}
      button={
        <StreaksButton
          className={s.button}
          disabled={isLoading}
          onClick={() => {
            if (canRestore) {
              restoreStreak(livesRequired);
            } else {
              navigate('/pocket');
            }
          }}
        >
          <Trans
            i18nKey={
              isLoading
                ? 'StreaksPage.StatusPanelButton.PurchasingLives'
                : canRestore
                  ? 'StreaksPage.StatusPanelButton.StreakAlmostLostExchangeLives'
                  : 'StreaksPage.StatusPanelButton.StreakAlmostLostPurchaseLives'}
            count={livesRequired}
          />
          {isLoading && (
            <span className={s.spinner}>
              {/*TODO: Replace with CSS in future*/}
              <svg
                className={s.spinnerIcon}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3C10.3392 3 8.71074 3.45956 7.29495 4.32781C5.87915 5.19606 4.73122 6.43914 3.97821 7.91945C3.2252 9.39976 2.89649 11.0596 3.02846 12.7151C3.16043 14.3707 3.74793 15.9575 4.72594 17.2998C5.70394 18.6421 7.0343 19.6877 8.56977 20.3207C10.1052 20.9537 11.7859 21.1495 13.4258 20.8864C15.0656 20.6232 16.6007 19.9115 17.861 18.8299C19.1214 17.7484 20.0579 16.3391 20.5669 14.7582"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
        </StreaksButton>
      }
    >
      <Trans
        i18nKey={canRestore
          ? 'StreaksPage.StatusPanelText.StreakAlmostLostExchangeLives'
          : 'StreaksPage.StatusPanelText.StreakAlmostLostPurchaseLives'}
        count={livesRequired}
      />
    </StatusPanel>
  );
}
