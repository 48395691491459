import { useEffect } from 'react';

/**
 * Logs the error message in case, it is truthy.
 * @param err - error to log.
 * @param message - message to prepend.
 */
export function useErrorLog(message: string, err: unknown) {
  useEffect(() => {
    err && console.error(message, err);
  }, [err, message]);
}