import { type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import {
  THEME,
  TonConnectUIProvider,
  useTonConnectModal,
  useTonConnectUI,
  useTonWallet,
  type Wallet,
} from '@tonconnect/ui-react';
import { toUserFriendlyAddress } from '@tonconnect/sdk';
import mixpanel from 'mixpanel-browser';

import { TonConnectContext } from './context';
import { useRegisterWallet } from '../../hooks/pocket/mutations/useRegisterWallet';

function Inner({ children }: PropsWithChildren) {
  const [tonConnectUI] = useTonConnectUI();
  const modal = useTonConnectModal();
  const wallet = useTonWallet();
  const [registeredWallet, setRegisteredWallet] = useState<Wallet>();

  const {
    trigger: registerWallet,
    isMutating: isRegisteringWallet,
    error: walletRegisterError,
  } = useRegisterWallet();

  const disconnectWallet = useCallback(() => {
    tonConnectUI.disconnect().catch(e => {
      console.error('Error disconnecting wallet', e);
    });
  }, [tonConnectUI]);

  useEffect(() => {
    if (isRegisteringWallet) {
      return;
    }

    // Disconnect the wallet in case, we were unable to register it.
    if (walletRegisterError) {
      disconnectWallet();
      return;
    }

    // Otherwise, notify the system, register was successful.
    if (wallet) {
      setRegisteredWallet(wallet);
    }
  }, [isRegisteringWallet, walletRegisterError, disconnectWallet, wallet]);

  // Whenever the user connects his wallet, we would like to register it in our system and send
  // some analytics.
  useEffect(() => {
    return tonConnectUI.onStatusChange(wallet => {
      if (wallet) {
        registerWallet(toUserFriendlyAddress(wallet.account.address));

        mixpanel.track('wallet_connected', {
          'wallet_address': wallet.account,
          'wallet_type': wallet.appName,
        });
      } else {
        setRegisteredWallet(undefined);
      }
    });
  }, [tonConnectUI, registerWallet]);

  const context = useMemo(() => ({
    ...registeredWallet ? {
      wallet: {
        data: registeredWallet,
        address: toUserFriendlyAddress(registeredWallet.account.address),
      },
    } : {},
    modal,
    isRegisteringWallet,
    disconnectWallet,
  }), [registeredWallet, modal, disconnectWallet, isRegisteringWallet]);

  return (
    <TonConnectContext.Provider value={context}>
      {children}
    </TonConnectContext.Provider>
  );
}

export function TonConnectProvider({ children, returnUrl, manifestUrl }: PropsWithChildren<{
  manifestUrl: string;
  returnUrl: `${string}://${string}`;
}>) {
  return (
    <TonConnectUIProvider
      manifestUrl={manifestUrl}
      uiPreferences={{
        theme: window.Telegram.WebApp.colorScheme === 'dark'
          ? THEME.DARK
          : THEME.LIGHT,
      }}
      actionsConfiguration={{ twaReturnUrl: returnUrl }}
    >
      <Inner>
        {children}
      </Inner>
    </TonConnectUIProvider>
  );
}