import useSWR, { type SWRResponse } from 'swr';

import { getItemFromStorage, setItemToStorage } from '../../../../hooks/hooks';

const STORAGE_KEY = 'hasPendingPocketOrder';

/**
 * Hook controlling the pending order flag stored in the Cloud Storage.
 */
export function usePendingOrder(): [SWRResponse<boolean>, {
  set(hasPendingOrder: boolean): void;
}] {
  const response = useSWR('CS_HAS_PENDING_ORDER', () => {
    return getItemFromStorage(STORAGE_KEY, 5000).then(r => r === '1');
  });

  return [response, {
    set(hasPendingOrder) {
      response.mutate(hasPendingOrder, { revalidate: false }).catch(console.error);
      setItemToStorage(
        STORAGE_KEY,
        hasPendingOrder ? '1' : '',
        5000,
      ).catch(console.error);
    },
  }];
}