import { publicUrl } from '../../utils/url';
import { createSrcSet } from '../../utils/createSrcSet';

interface Artifact {
  id: string;
  img: {
    small: {
      src: string;
      srcSet: string;
      width: number;
      height: number;
      background: {
        color: string;
        src: string;
        width: number;
        height: number;
        deltaX: number;
        deltaY: number;
      };
    };
    large?: {
      src: string;
      srcSet: string;
    };
  };
  titleKey: string;
  shortDescriptionKey: string;
  descriptionKey: string;
}

function createArtifactImg(
  name: string,
  [width, height, background]: [
    width: number,
    height: number,
    background: [
      color: string,
      width: number,
      height: number,
      deltaX: number,
      deltaY: number,
    ],
  ],
  large: [maxWidth: number, maxHeight: number],
) {
  const baseUrl = `/pocket/artifacts/${name}`;
  const smallBaseUrl = `${baseUrl}/small/${name}-small`;
  const largeBaseUrl = `${baseUrl}/large/${name}-large`;

  return {
    small: {
      src: publicUrl(`${smallBaseUrl}.png`),
      srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
        return `${smallBaseUrl}${suffix}.png`;
      })),
      width,
      height,
      background: {
        color: background[0],
        src: `${baseUrl}/bg-blur.png`,
        width: background[1],
        height: background[2],
        deltaX: background[3],
        deltaY: background[4],
      },
    },
    large: {
      src: publicUrl(`${largeBaseUrl}.png`),
      srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
        return `${largeBaseUrl}${suffix}.png`;
      })),
      maxWidth: large[0],
      maxHeight: large[1],
    },
  };
}

const mappings: Record<string, Artifact> = {
  LIVES: {
    id: '0EGCva0vzQVay7SX_FUmL_UrVGr1c2JykZRijmDpTx38Sdpv',
    img: createArtifactImg(
      'lives',
      [113, 106, ['#140304', 230, 225, 0, 10]],
      [188, 180],
    ),
    titleKey: 'PocketPage.LivesArtifactTitle',
    shortDescriptionKey: 'PocketPage.LivesArtifactShortDescription',
    descriptionKey: 'PocketPage.LivesArtifactDescription',
  },
  TICKET: {
    id: '0HCHka0kzPGax7SW_FUmL_UrVGr1c2JxkZRikntdTx38Sdhm',
    img: createArtifactImg(
      'fortune-wheel-ticket',
      [143, 109, ['#0F0927', 165, 127, -8, -9]],
      [256, 198],
    ),
    titleKey: 'PocketPage.FortuneWheelTicketTitle',
    shortDescriptionKey: 'PocketPage.FortuneWheelTicketShortDescription',
    descriptionKey: 'PocketPage.FortuneWheelTicketDescription',
  },
  SBT: {
    id: 'UQBmRarKjQzK0go_spVH2-dL3rvad1gWX16eqzcI3XnjDXO9',
    img: createArtifactImg(
      'tapps-center-ticket',
      [167, 167, ['#030515', 187, 187, -23, -11]],
      [296, 296],
    ),
    titleKey: 'PocketPage.CenterTicketTitle',
    shortDescriptionKey: 'PocketPage.CenterTicketShortDescription',
    descriptionKey: 'PocketPage.CenterTicketDescription',
  },
};

// fixme: collection id should not be here
export function findArtifact(type: string | undefined, collectionId: string): Artifact | undefined {
  return type
    ? mappings[type]
    : Object.values(mappings).find(item => item.id === collectionId);
}