import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr';

import { useApiClient } from '../../../providers/ApiClientProvider/context';

interface Response {
  points: number;
  boosts: number;
  lives: number;
  spins: number;
  nftTickets: number;
}

/**
 * Requests user pocket information.
 */
export function useUserPocket(
  options?: SWRConfiguration<Response>
): SWRResponse<Response> {
  const client = useApiClient();
  return useSWR<Response>('USER_POCKET', client.getUserPocket.bind(client), {
    ...options,
    onError(err, key, config) {
      console.error('Error fetching user pocket', err);
      options && options.onError && options.onError(err, key, config);
    },
  });
}