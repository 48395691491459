import { createContext, useContext } from 'react';

import { ApiClient } from '../../helpers/ApiClient';

export const ApiClientContext = createContext<ApiClient | undefined>(undefined);

export function useApiClient(): ApiClient {
  const value = useContext(ApiClientContext);
  if (!value) {
    throw new Error('Used outside ApiClientProvider');
  }
  return value;
}