import React from 'react';
import useSWR from 'swr';
import { TWAApplication, TWALaunchHistoryItem } from '../../../../types/entities';
import { PureTWAAppList } from '../../../uilib/AppList/PureTWAAppList';

import styles from '../page.module.scss';

import { getRecentlyLaunchedFromApi } from '../../../utils/api/requests';
import { useTWAUser } from '../../../hooks';
import { TWAAppList } from '../../../uilib/AppList/TWAAppList';
import { TWAFooter } from '../../../uilib/Footer/TWAFooter'
import { TWAHeader } from '../../../uilib/Header/TWAHeader';
import { WithColoredUI } from '../../_components/WithColoredUI';

export default function Recent() {
    const twaUser = useTWAUser();
    const { data: recent, error, isLoading } = useSWR(twaUser, getRecentlyLaunchedFromApi);

    const renderRecent = () => {
        if (error) {
            return (
                <TWAAppList/>
            )
        }

        if (!recent) {
            return (
                <TWAAppList/>
            )
        }

        const apps: Array<TWAApplication> = recent.data.applications;
        const history: Array<TWALaunchHistoryItem> = recent.data.history;
        const orderedApps: TWAApplication[] = [];

        history.forEach((item) => {
            const app = apps.find(
                (app) => app.id === Number(item.app_id)
            );

            if (app) {
                orderedApps.push(app);
            }
        });

        return (
            <PureTWAAppList
                applications={orderedApps}
                isLoading={isLoading}
            />
        );
    }

    return (
      <WithColoredUI>
        <main className={styles.main}>
            <TWAHeader />
            {renderRecent()}
            <div className={styles.footerWrapper}>
                <TWAFooter />
            </div>
        </main>
      </WithColoredUI>
    )
}
