import { type Context, useContext } from 'react';

/**
 * @returns Hooks preventing its usage outside parent context.
 * @param context - context to use.
 */
export function createUseContext<T>(context: Context<T>) {
  return (): Exclude<T, undefined> => {
    const ctx = useContext(context);
    if (!ctx) {
      throw new Error(`Used outside ${context.displayName} context`);
    }
    return ctx as Exclude<T, undefined>;
  }
}