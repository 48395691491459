import { useMemo } from 'react';

import { useStreaksDailyTasks } from './useStreaksDailyTasks';

export interface DailyTaskMainApp {
  id: number;
  appId: number;
  title?: string | null;
  icon: {
    url: string;
    name?: string | null;
  };
  url: string;
  editorsChoice?: boolean | null;
  description?: string | null;
}

/**
 * @returns Daily task main application information.
 */
export function useStreaksDailyTaskMainApp(): DailyTaskMainApp | 'loading' | undefined {
  const { isLoading, data } = useStreaksDailyTasks();

  return useMemo(() => {
    if (isLoading) {
      return 'loading';
    }
    if (!data) {
      return;
    }
    const { app, id: taskId } = data.main || {};
    let appInfo: DailyTaskMainApp | undefined;
    if (app && taskId) {
      const { url } = app;
      if (url) {
        appInfo = {
          id: taskId,
          appId: app.id,
          title: app.title,
          icon: app.icon,
          url: app.webappUrl || url,
          editorsChoice: app.editorsChoice,
          description: app.description,
        };
      }
    }
    return appInfo;
  }, [isLoading, data]);
}
