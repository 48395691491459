import { StreaksLottery } from '../../../_components/StreaksLottery/StreaksLottery';
import { Section } from './Section/Section';

export function Lottery() {
  return (
    <Section>
      <StreaksLottery/>
    </Section>
  );
}
