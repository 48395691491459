import { createContext, useContext } from 'react';

export interface ServerTimeContextType {
  isLoading: boolean;
  /**
   * @returns Current time according to the difference with server.
   */
  getCurrentTime(): Date;
}

export const ServerTimeContext = createContext<ServerTimeContextType | undefined>(undefined);

export function useServerTimeContext(): ServerTimeContextType {
  const ctx = useContext(ServerTimeContext);
  if (!ctx) {
    throw new Error('Used outside ServerTimeProvider');
  }
  return ctx;
}