import { Leagues } from './components/Leagues/Leagues';
import { InviteFriends } from './components/InviteFriends/InviteFriends';
import { CurrentStreak } from './components/CurrentStreak/CurrentStreak';
import { DailyTask } from './components/DailyTask/DailyTask';
import { Links } from './components/Links/Links';
import { useScrollRestoration } from '../../../hooks/useScrollRestoration';
import { PocketPanels } from './components/PocketPanels/PocketPanels';
import { WithColoredUI } from '../../_components/WithColoredUI';
import { Lottery } from './components/Lottery';

import s from './StreaksPage.module.scss';

export function StreaksPage() {
  const { ref } = useScrollRestoration('streaksPage', {
    debounceTime: 200,
  });

  return (
    <WithColoredUI color="#171628">
      <div className={s.root} ref={ref}>
        <div className={s.inner}>
          <CurrentStreak/>
          <Lottery/>
          <PocketPanels/>
          <DailyTask/>
          <InviteFriends/>
          <Leagues/>
          <Links/>
        </div>
      </div>
    </WithColoredUI>
  );
}
