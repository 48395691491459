export function copyToClipboard(text: string): Promise<string> {
    return new Promise((resolve, reject) => {
        navigator.clipboard.writeText(text).then(() => {
            resolve(text);
        }).catch((e) => {
            console.error('Failed to copy text to clipboard', e);
            reject(e);
        });
    });
}
