import React, { useCallback, useEffect, useMemo, useState } from "react";
import mixpanel from 'mixpanel-browser';
import { Heading } from "../../../uilib/Typography/Typography";
import { useReferralPrograms } from "../../../hooks/referralPrograms/useReferralPrograms";
import { useReferrals } from "../../../hooks/referralPrograms/useReferrals";
import styles from "./styles.module.scss";
import { copyToClipboard } from "../../../utils/clipboard";
import { openTelegramLink } from "../../../utils/common";
import { Link } from "react-router-dom";
import { ReferralItem, ReferralItemsSkeleton } from "./components/ReferralItem";
import { useTranslation } from "react-i18next";
import { Ripple } from "../../../uilib/Ripple/Ripple";
import { Toast } from "../../../uilib/Toast/Toast";
import system from "../../../services/System";
import { formatNumberWithSign } from "../../_components/JettonInfo/utils";
import { WithColoredUI } from '../../_components/WithColoredUI';
import { StreaksShimmer } from "../../_components/StreaksShimmer/StreaksShimmer";
import { StreaksAnimatePresence } from "../../_components/StreaksAnimatePresence";

interface CardProps {
    heading?: string;
    hint?: string;
    link?: {url: string, anchor: string};
    children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ heading, children, hint, link }) => {
    return (
        <div className={styles.card}>
            <div className={styles.cardHeading}>
                <div className={styles.cardHeadingTitle}>
                    <Heading type={"h3"} weight={"bold"}>
                        {heading}
                    </Heading>
                    {hint ? (
                        <p className={styles.cardHeadingHint}>{hint}</p>
                    ) : null}
                </div>
                {link ? (
                    <div className={styles.cardHeadingLink} draggable={false}>
                        {link.anchor}
                    </div>
                ) : null}
                {/* // todo rollback */}
                {/* {link ? (
                    <Link to={link.url} className={styles.cardHeadingLink} draggable={false}>
                        {link.anchor}
                    </Link>
                ) : null} */}
            </div>
            {children}
        </div>
    );
};

export default function ReferralProgramPage() {
    const {t} = useTranslation();
    const {programs} = useReferralPrograms();
    const program = programs[0];
    const { isLoading: isReferralsLoading, referrals, referralsTotal } = useReferrals({programId: program?.programId});
    const [copiedStatus, setCopiedStatus] = useState<'success' | 'error' | null>(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            setCopiedStatus(null);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [copiedStatus]);
    const referralsListUrl = useMemo(() => {
        if (!program) {
            return '';
        }

        return `/referral-programs/${program.programId}/referrals`;
    }, [program]);
    const referralLink = useMemo(() => {
        if (!program) {
            return '';
        }

        return `https://t.me/tapps/app?startapp=ref_${program.referralCode}`;
    }, [program]);
    const copyReferralLink = useCallback(async () => {
        if (!referralLink) {
            return;
        }

        try {
            await copyToClipboard(referralLink);
            window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success');
            setCopiedStatus('success');
        } catch (error) {
            window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('error');
            setCopiedStatus('error');
        }
    }, [referralLink]);
    const shareLink = useCallback(() => {
        if (!referralLink) {
            return;
        }

        window.Telegram?.WebApp?.HapticFeedback?.impactOccurred('light');

        const text = t('refInviteText');
        openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${text}`);

        mixpanel.track('referral_sent', {
            program_id: program?.programId
        });
    }, [referralLink, t, program]);

    const steps = useMemo(() => [
        t('refExplainerStep1'),
        t('refExplainerStep2'),
        t('refExplainerStep3'),
    ], [t]);

    return (
      <WithColoredUI color="#1c1c1e">
        <div className={styles.referralContainer}>
            <Card heading={t('yourInviteLink')}>
                <div className={styles.cardContainer}>
                    {referralLink
                        ? <Ripple className={styles.rippleWrapper} onClick={referralLink ? () =>
                                    system.delayAction(() => {
                                        copyReferralLink();
                                    }) : undefined
                            }>
                            <div className={styles.refLink}>
                                <p>{referralLink}</p>
                                <svg
                                    width="17"
                                    height="20"
                                    viewBox="0 0 17 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.867676 16.6187V6.62842C0.867676 5.00977 1.72461 4.15283 3.32861 4.15283H4.54443V2.79785C4.54443 1.1792 5.40137 0.314941 7.00537 0.314941H9.72266C10.6089 0.314941 11.2974 0.549316 11.8687 1.12793L15.4868 4.81934C16.0874 5.43457 16.2998 6.0791 16.2998 7.08984V12.7881C16.2998 14.4067 15.4429 15.2637 13.8389 15.2637H12.623V16.6187C12.623 18.2373 11.7661 19.1016 10.1621 19.1016H3.32861C1.71729 19.1016 0.867676 18.2446 0.867676 16.6187ZM11.854 8.8916C12.4912 9.53613 12.623 10.0342 12.623 11.0522V13.6377H13.6704C14.3442 13.6377 14.6665 13.2861 14.6665 12.6489V6.73096H11.583C10.6455 6.73096 10.1108 6.21094 10.1108 5.25879V1.94824H7.17383C6.5 1.94824 6.17773 2.2998 6.17773 2.93701V4.16016C6.91748 4.18945 7.39355 4.37256 7.93555 4.91455L11.854 8.8916ZM11.4146 5.07568C11.4146 5.32471 11.5244 5.42725 11.7661 5.42725H14.2417L11.4146 2.55615V5.07568ZM2.50098 16.4795C2.50098 17.124 2.82324 17.4683 3.48975 17.4683H9.99365C10.6675 17.4683 10.9897 17.124 10.9897 16.4795V11.3013H7.32764C6.18506 11.3013 5.62842 10.752 5.62842 9.60938V5.77881H3.49707C2.82324 5.77881 2.50098 6.13037 2.50098 6.76758V16.4795ZM7.4668 9.91699H10.814L7.02002 6.07178V9.47021C7.02002 9.77783 7.15918 9.91699 7.4668 9.91699Z"
                                        fill="#fff"
                                        fillOpacity="0.9"
                                    />
                                </svg>
                            </div>
                        </Ripple>
                        : <StreaksShimmer height={70} />
                    }
                    {referralLink
                        ? <Ripple className={styles.rippleWrapper} onClick={referralLink ? () =>
                                system.delayAction(() => {
                                    shareLink();
                                }) : undefined
                            }>
                                <button type="button" className={styles.shareBtn}>
                                    {t('shareYourLink')}
                                </button>
                        </Ripple>
                        : <StreaksShimmer height={50} />
                    }
                </div>
            </Card>
            <StreaksAnimatePresence>
                {isReferralsLoading || referrals.length > 0
                    ? <Card
                        heading={t('invitees')}
                        hint={`${t('top')} 5`}
                        link={
                            referralsListUrl && referralsTotal > 5
                            ? {url: referralsListUrl, anchor: `${t('total')} ${formatNumberWithSign(referralsTotal)}`}
                            : undefined
                        }>
                        <div className={styles.inviteesContainer}>
                            {isReferralsLoading ? <ReferralItemsSkeleton /> : referrals.map((referral) => {
                                return (
                                    <ReferralItem
                                        key={referral.id}
                                        name={[referral.firstName, referral.lastName].join(" ").trim() || referral.username}
                                        joinedAt={referral.joinedAt}
                                    />
                                );
                            })}
                        </div>
                    </Card>
                    : null
                }
            </StreaksAnimatePresence>
            <Card heading={t('refExplainerHeading')}>
                <div className={styles.stepsContainer}>
                    {steps.map((step, index) => {
                        return (
                            <div key={step} className={styles.step}>
                                <span className={styles.stepIndex}>
                                    {index + 1}
                                </span>{" "}
                                <p
                                    className={styles.stepText}
                                    dangerouslySetInnerHTML={{
                                        __html: step,
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </Card>
            {copiedStatus
                ? <Toast type={copiedStatus}>{copiedStatus === 'success'
                        ? t('clipboardCopySuccess')
                        : t('clipboardCopyError')}
                    </Toast>
                : null
            }
        </div>
      </WithColoredUI>
    );
}
