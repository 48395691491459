import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { TelegramWebApps } from '../../types/common';
import isMobile from '../utils/isMobile';
import { telegramCloudStorage } from '../services/utils';

export const useMixpanel = () => {
    useEffect(() => {
        mixpanel.init(
            process.env.REACT_APP_MIXPANEL_TOKEN!,
            {
                track_pageview: true,
                persistence: 'localStorage',
            },
        );

        mixpanel.set_group('Platform', getPlatform());

        try {
            const initData =
                window.Telegram?.Utils?.urlParseQueryString<TelegramWebApps.WebAppInitData>(window.Telegram?.WebApp?.initData);

            if (initData.user) {
                const user = JSON.parse(initData.user);

                if (user) {
                    mixpanel.identify(String(user.id));
                }
            }
        } catch (e) {
        }

        mixpanel.track_links('a', 'Link Click');
    }, []);

    return mixpanel;
};

export const useCloudStorage = () => {
    return {
        getItemFromStorage,
        setItemToStorage,
    };
};

export function getItemFromStorage(key: string, timeout?: number): Promise<string> {
    return telegramCloudStorage
        .get(key, { timeout: timeout || 2000 })
      // todo: do something
        .catch(() => '');
}

export function setItemToStorage(
    key: string,
    value: string,
    timeout?: number,
) {
    return telegramCloudStorage
        .set(key, value, { timeout: timeout || 2000 })
        .catch(console.error);
}

const getPlatform = () => {
    try {
        let platform: string = window.Telegram?.WebApp?.platform;

        if (platform === 'unknown') {
            platform = isMobile(navigator).any ? 'tapps_mobile_web' : 'tapps_web';
        }

        return platform;
    } catch (error) {
        return 'unknown';
    }
};
