import classNames from 'classnames';
import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { useTWAPlatform } from '../../hooks';
import styles from './styles.module.scss';

export interface IButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  type: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'link' | 'alternative' | 'inversed' | 'context';
  disableActiveState?: boolean;
  disablePointer?: boolean;
  shine?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, PropsWithChildren<IButtonProps>>(
  (props, ref) => {
    const {
      type,
      shine,
      children,
      className,
      disablePointer,
      disableActiveState,
      disabled,
      ...rest
    } = props;

    const platform = useTWAPlatform();

    const buttonClassNames = classNames(
      className,
      styles.button,
      styles[`button__${type}`],
      {
        [styles['button__shine']]: shine,
        [styles['button__pointerDisabled']]: disablePointer || disabled,
        [styles['button__activeStateDisabled']]: disableActiveState || disabled,
      },
    );

    return (
      <button ref={ref} className={buttonClassNames} disabled={disabled} {...rest}>
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
