import { createContext, useContext } from 'react';

import { LOCALES } from './i18n';

export interface LocaleContextType {
  changeLocale?: (locale: LOCALES) => void,
  locale: LOCALES,
  isLoading: boolean;
}

export const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

export function useLocaleContext(): LocaleContextType {
  const ctx = useContext(LocaleContext);
  if (!ctx) {
    throw new Error('Called outside LocaleProvider');
  }
  return ctx;
}