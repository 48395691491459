import cn from 'classnames';

import { Button as UIButton, type IButtonProps } from '../../../uilib/Button/Button';
import { Body } from '../../../uilib/Typography/Typography';

import s from './StreaksButton.module.scss';

export interface StreaksButtonProps extends Omit<IButtonProps, 'type'> {
  rounded?: boolean;
}

export function StreaksButton({
  className,
  children,
  rounded,
  ...rest
}: StreaksButtonProps) {
  return (
    <UIButton
      {...rest}
      className={cn(className, s.root, rounded && s.rounded)}
      type="primary"
    >
      <Body weight="semi-bold">
        {children}
      </Body>
    </UIButton>
  );
}
