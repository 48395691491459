import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useAppStateContext } from '../../../../providers/AppStateProvider/context';

import styles from "./Onboarding.module.scss";

export interface OnboardingProps {
    onClose: () => void;
}

const onboardingItems = [
    {
        'img' : "/streaks/onboarding/onboarding-1.jpg",
        "title" : "Introducing Streak system in Telegram Apps Center!",
        "description" : "Keep your streak alive and get a chance to win rewards, by being active in the apps center!"
    },
    {
        'img' : "/streaks/onboarding/onboarding-2.jpg",
        "title" : "It’s simple!",
        "description" : "Each day you complete a task, your streak increases. Log into a mini app today, play a game, or explore new features, and watch your streak grow."
    },
    {
        'img' : "/streaks/onboarding/onboarding-3.jpg",
        "title" : "Keep an eye on your progress!",
        "description" : "Our progress bars and widgets will help you stay informed of your streak and show how many days are left to new league!"
    },
    {
        'img' : "/streaks/onboarding/onboarding-4.jpg",
        "title" : "The Higher You Climb, the Better the Prizes!",
        "description" : "Keeping the streak alive for more time will put you in a league, more days in the streak means higher league!"
    },
    {
        'img' : "/streaks/onboarding/onboarding-5.jpg",
        "title" : "Don’t miss out - Get Notifications!",
        "description" : "To ensure you are making streak progress its useful to turn on notifications so you don’t miss a task!"
    },
    {
        'img' : "/streaks/onboarding/onboarding-6.jpg",
        "title" : "Invite Friends, Earn More!",
        "description" : "Invite friends with your personal referral link and get more points!"
    }
];

export const Onboarding: React.FC<OnboardingProps> = ({onClose}) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const onClick = useCallback(() => {
        const nextIndex = activeSlideIndex + 1;
        if (nextIndex <= onboardingItems.length - 1) {
            setActiveSlideIndex(nextIndex);
            window.Telegram?.WebApp?.HapticFeedback.impactOccurred('light');
        } else {
            window.Telegram?.WebApp?.HapticFeedback.notificationOccurred('success');
            onClose();
        }
    }, [activeSlideIndex, onClose]);

    const activeSlideData = useMemo(() => {
        return onboardingItems[activeSlideIndex];
    }, [activeSlideIndex]);

    const { isLoaderVisible } = useAppStateContext();

    useEffect(() => {
        // Not showing the main button until the loader is visible.
        if (isLoaderVisible) {
            return;
        }

        window.Telegram?.WebApp?.MainButton.setParams({
            text: activeSlideIndex < onboardingItems.length - 1 ? 'Next' : 'Done',
            is_active: true,
            is_visible: true,
        });
        window.Telegram?.WebApp?.MainButton.onClick(onClick);

        return () => {
            window.Telegram?.WebApp?.MainButton.setParams({
                text: 'Continue',
                is_visible: false,
                is_active: false,
            });
            window.Telegram?.WebApp?.MainButton.offClick(onClick);
        }
    }, [onClick, activeSlideIndex, isLoaderVisible]);

    return <div className={styles.root}>
        <div className={styles.onboardingItems}>
            <div key={activeSlideData.img} className={styles.onboardingItem}>
                <div className={styles.onboardingBg} style={{ backgroundImage: `url(${activeSlideData.img})` }}>
                </div>
                <div className={styles.onboardingContentContainer}>
                    <div className={styles.onboardingContent}>
                        <div className={styles.onboardingContentTitle}>{activeSlideData.title}</div>
                        <div className={styles.onboardingContentDesc}>
                            {activeSlideData.description}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className={styles.onboardingCarouselContainer}>
        <ul className={styles.onboardingCarousel}>
            {
                onboardingItems.map((onboardingItem, index) => {
                    return (<li key={onboardingItem.img} className={activeSlideIndex === index ? styles.onboardingCarouselItemActive : styles.onboardingCarouselItem }></li>)
                })
            }
        </ul>
        </div>

    </div>;
}
