import { initUtils } from '@telegram-apps/sdk-react';

const { CloudStorage } = window.Telegram.WebApp;

function timeoutPromise(timeout: number): Promise<never> {
  return new Promise((_res, rej) => {
    setTimeout(() => {
      rej(new Error('Timed out'));
    }, timeout);
  });
}

export const telegramUtils = initUtils();
export const telegramCloudStorage = {
  get(key: string, options?: { timeout?: number }): Promise<string> {
    options ||= {};
    return Promise.race([
      new Promise<string>((res, rej) => {
        CloudStorage.getItem(key, (err, data) => {
          if (err) {
            return rej(new Error(err));
          }
          res(data || '');
        });
      }),
      timeoutPromise(options.timeout || 5000),
    ]);
  },
  set(key: string, value: string, options?: {
    timeout?: number
  }): Promise<void> {
    options ||= {};

    return Promise.race([
      new Promise<void>((res, rej) => {
        CloudStorage.setItem(key, value, (err, data) => {
          if (err) {
            return rej(new Error(err));
          }
          res();
        });
      }),
      timeoutPromise(options.timeout || 5000),
    ]);
  },
};
