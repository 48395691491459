import cn from 'classnames';

import { Button as UIButton, type IButtonProps } from '../../../../uilib/Button/Button';

import s from './Button.module.scss';

interface Props extends Omit<IButtonProps, 'type'> {
  rounded?: boolean;
}

export function Button({ className, children, rounded, ...rest }: Props) {
  return (
    <UIButton
      {...rest}
      className={cn(className, s.root, rounded && s.rounded)}
      type="primary"
    >
      {children}
    </UIButton>
  );
}