import { useLoaderData } from "react-router-dom";
import { useReferrals } from "../../../../hooks/referralPrograms/useReferrals";
import { ReferralItem, ReferralItemsSkeleton } from "../components/ReferralItem";
import styles from "../styles.module.scss";
import { WithColoredUI } from "../../../_components/WithColoredUI";

export const ReferralsPage = () => {
    const programId = useLoaderData() as string;
    const {  isLoading: isReferralsLoading, referrals } = useReferrals({programId: Number(programId), showAll: true});
    return <WithColoredUI color="#1c1c1e">
        <div className={styles.referralContainer}>
        {
            isReferralsLoading || referrals.length > 0
            ?
                isReferralsLoading
                    ? <ReferralItemsSkeleton />
                    : referrals.map((referral) => {
                        return <ReferralItem
                            key={referral.id}
                            name={[referral.firstName, referral.lastName].join(" ").trim() || referral.username}
                            joinedAt={referral.joinedAt}
                        />;
                    })
            : null
        }
        </div>
    </WithColoredUI>
};
