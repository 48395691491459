import { StreaksShimmer } from '../StreaksShimmer/StreaksShimmer';
import { Progressbar } from '../Progressbar/Progressbar';

import s from './StreaksProgress.module.scss';
import { Trans } from 'react-i18next';

export function StreaksProgress({ data }: {
  data: 'loading' | {
    current: number;
    from: number;
    to: number;
    nextLeague: {
      title: string;
      streakDays: number
    }
  }
}) {
  return data === 'loading'
    ? (
      <div className={s.root}>
        <div className={s.bar}>
          <StreaksShimmer className={s.barItem}/>
          <StreaksShimmer className={s.star}/>
          <StreaksShimmer className={s.barItem}/>
        </div>
        <div className={s.meta}>
          <StreaksShimmer width={100} height={16}/>
          <StreaksShimmer width={40} height={16}/>
        </div>
      </div>
    )
    : (
      <Progressbar
        {...data}
        footer={{
          left: <Trans
            i18nKey="StreaksProgress.DaysLeft"
            values={{ league: data.nextLeague.title }}
          />,
          right: `${data.current} / ${data.nextLeague.streakDays}`,
        }}
      />
    );
}